<template>
  <a-list
      class="form-list"
      item-layout="horizontal"
      :dataSource="listData"
      :pagination="{ ...paginationProps, current: currentPage }"
  >
    <a-list-item v-for="item in listData" :key="item.id" :style="{ display: 'flex', alignItems: 'center', userSelect: 'none', backgroundColor: item.read ? '#F5F5F5' : '#EAEAEA' }">
      <a-avatar
          size="large"
          shape="circle"
          :style="{ backgroundColor: getRandomColor(item.fromName), marginLeft: '10px', marginRight: '16px' }"
      >
        {{ item.fromName.charAt(0).toUpperCase() }}
      </a-avatar>
      <div style="flex: 1;">
        <h3 style="color: #504f4f; margin: 2px 0 1px;">
          {{ item.fromName }}
          <span style="font-size: 16px; font-weight: 500; color: red">{{ item.name == "other"? "message" : item.name }}</span>
          you
        </h3>
        <h4>{{ item.fromEmail }}</h4>
      </div>
      <span style="margin-left: 40px">{{formatDate(item.date)}}</span>
      <template #actions>
        <a @click="handleFollowClick(item)">follow</a>
        <a @click="handleReadClick(item)">{{ item.read ? 'read' : 'unread' }}</a>
        <a @click="handleDeleteClick(item)">delete</a>
      </template>
    </a-list-item>
  </a-list>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import axios from "axios";
import store from "@/store";
import {message, Modal} from "ant-design-vue";

export default defineComponent({
  name: 'customer-friend',

  setup() {
    const listData = ref();
    const currentPage = ref(1);

    const formQueryReq = ref({
      page: 1,
      size: 50,
      toId: store.state.user.id,
      toName: store.state.user.loginName
    })

    const friendFollowReq = ref({
      userId: "",
      followerId: ""
    })

    const formSaveReq = ref({
      name: "",
      fromId: 0,
      fromName: "",
      fromEmail: "",
      toId: 0,
      toName: "",
      emergency: 0,
      content: "",
      contentId: 0
    });

    const clearFriendFollowReq = () => {
      friendFollowReq.value.userId = "";
      friendFollowReq.value.followerId = "";
    }

    const clearFormSaveReq = () => {
      formSaveReq.value.name = "";
      formSaveReq.value.fromId = 0;
      formSaveReq.value.fromName = "";
      formSaveReq.value.fromEmail = "";
      formSaveReq.value.toId = 0;
      formSaveReq.value.toName = "";
      formSaveReq.value.emergency = 0;
      formSaveReq.value.content = "";
      formSaveReq.value.contentId = 0;
    }

    const getRandomColor = (id: any) => {
      const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff0000', '#0000ff'];

      const storedColor = localStorage.getItem(id);
      if (storedColor) {
        return storedColor;
      } else {
        const randomIndex = Math.floor(Math.random() * colorList.length);
        const randomColor = colorList[randomIndex];
        localStorage.setItem(id, randomColor);
        return randomColor;
      }
    }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const now = new Date();
      const diff = Math.abs(now.getTime() - date.getTime());
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (diff < 1000 * 60) {
        return 'Just now';
      } else if (diff < 1000 * 60 * 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24 * 30) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
      } else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    }

    const handleFormQuery = (params: any) => {
      listData.value = [];
      formQueryReq.value.page = params.page;
      formQueryReq.value.size = params.size;
      axios.post("/form/request-follow", formQueryReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id,
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          listData.value = data.content.list;
          console.log(listData.value)
          paginationProps.value.total = data.content.total;
        }
      });
    }

    const paginationProps = ref({
      showQuickJumper: true,
      pageSize: 6,
      total: 0,

      onChange: (page: number) => {
        currentPage.value = page;
        handleFormQuery({
          page: currentPage.value,
          size: 6
        });
      }
    })

    const handleReadClick = (item: any) => {
      if (!item.read) {
        axios.get("/form/read/" + item.id, {
          headers: {
            'token': store.state.user.token,
            'id': store.state.user.id
          }
        }).then((response) => {
          const data = response.data;
          if (data.success) {
            message.success('Marked as read');
            handleFormQuery({
              page: currentPage.value,
              size: 6
            });
          } else {
            message.error(data.message);
          }
        });
      }
    }

    const handleDeleteClick = (item: any) => {
      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to delete this message? This action is irreversible!',
        onOk() {
          axios.get("/form/delete-form/" + item.id, {
            headers: {
              'token': store.state.user.token,
              'id': store.state.user.id
            }
          }).then((response) => {
            const data = response.data;
            if (data.success) {
              message.success('Deleted!');
              let pageNum = currentPage.value;
              if ((paginationProps.value.total - 1) % paginationProps.value.pageSize == 0) {
                pageNum = Math.max(1, pageNum-1);
              }
              handleFormQuery({
                page: pageNum,
                size: paginationProps.value.pageSize
              });
            } else {
              message.error(data.message);
            }
          });
        }
      });
    }

    const handleFollowClick = (item : any) => {
      handleReadClick(item);
      friendFollowReq.value.userId = store.state.user.id;
      friendFollowReq.value.followerId = item.fromId;

      axios.post('/friend/follow',  friendFollowReq.value, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          if (data.content) {
            message.success("Follow back success!")

            // send form request
            formSaveReq.value.name = "followed";
            formSaveReq.value.emergency = 4;
            formSaveReq.value.toId = item.fromId;
            formSaveReq.value.toName = item.fromName;
            formSaveReq.value.fromId = store.state.user.id;
            formSaveReq.value.fromName = store.state.user.loginName;
            formSaveReq.value.fromEmail = store.state.user.email;
            formSaveReq.value.content = "followed";
            formSaveReq.value.contentId = 0;

            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          } else {
            message.error("You have already followed this user.")
          }

          clearFriendFollowReq();
        } else {
          message.error(data.message);
        }
      }).then(() => {
        clearFormSaveReq();
      });
    }

    onMounted(() => {
      handleFormQuery({
        page: 1,
        size: 6
      });
    });

    return {
      listData,
      paginationProps,
      currentPage,

      getRandomColor,
      formatDate,

      handleReadClick,
      handleDeleteClick,
      handleFollowClick,
    }
  }
})
</script>

<style>

</style>