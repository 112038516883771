<template>
  <template v-if="isMobile">
    <div>
      <div class="tip">
        <div><b>Tips:</b></div>
        <div>• The statistical data is real and has a delay of about one minute.</div>
        <div>• The document tree can be expanded with unlimited levels and supports text, images and <b>Videos</b></div>
        <div>• The webpage is currently well adapted for <b>PC</b> devices.</div>
        <div>• If you have any questions or suggestions, please contact Lei Lu <a :href="'mailto:dlwsdqdws@gmail.com'" target="_blank">dlwsdqdws@gmail.com</a>.</div>
      </div>

      <a-row>
        <a-col :span="24">
          <a-card>
            <a-row>
              <a-col :span="12">
                <a-statistic title="Total Reads" :value="statistic.viewCount">
                  <template #suffix>
                    <UserOutlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic title="Total Likes" :value="statistic.voteCount">
                  <template #suffix>
                    <like-outlined />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <br>
      <a-row>
        <a-col :span="24">
          <a-card>
            <a-row>
              <a-col :span="12">
                <a-statistic title="Today's Reads" :value="statistic.todayViewCount">
                  <template #suffix>
                    <UserOutlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic title="Today's Likes" :value="statistic.todayVoteCount">
                  <template #suffix>
                    <like-outlined />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>


    </div>
  </template>


  <template v-if="!isMobile">
    <div>
      <div class="tip">
        <div><b>Tips:</b></div>
        <div>• The statistical data is real and has a delay of about one minute.</div>
        <div>• You will receive notifications when a document is liked.</div>
        <div>• The left menu is dynamically loaded and can be configured in the category management after logging in.</div>
        <div>• The document tree can be expanded with unlimited levels and supports text, images and <b>Videos</b></div>
        <div>• The webpage is currently only adapted for <b>PC</b> devices.</div>
        <div>If you have any questions or suggestions, please contact Lei Lu <a :href="'mailto:dlwsdqdws@gmail.com'" target="_blank">dlwsdqdws@gmail.com</a>.</div>
      </div>

      <a-row>
        <a-col :span="24">
          <a-card>
            <a-row>
              <a-col :span="8">
                <a-statistic title="Total Reading" :value="statistic.viewCount">
                  <template #suffix>
                    <UserOutlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="8">
                <a-statistic title="Total Likes" :value="statistic.voteCount">
                  <template #suffix>
                    <like-outlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="8">
                <a-statistic title="Like Rate" :value="statistic.voteCount / statistic.viewCount * 100"
                             :precision="2"
                             suffix="%"
                             :value-style="{ color: '#cf1322' }">
                  <template #suffix>
                    <like-outlined />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <br>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-card>
            <a-row>
              <a-col :span="12">
                <a-statistic title="Today's Reading" :value="statistic.todayViewCount" style="margin-right: 50px">
                  <template #suffix>
                    <UserOutlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic title="Today's Like" :value="statistic.todayVoteCount">
                  <template #suffix>
                    <like-outlined />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-row>
              <a-col :span="12">
                <a-statistic
                    title="Expected Reading Today"
                    :value="statistic.todayViewIncrease"
                    :value-style="{ color: '#0000ff' }"
                >
                  <template #suffix>
                    <UserOutlined />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic
                    title="Expected Reading Growth"
                    :value="statistic.todayViewIncreaseRateAbs"
                    :precision="2"
                    suffix="%"
                    class="demo-class"
                    :value-style="statistic.todayViewIncreaseRate < 0 ? { color: '#3f8600' } : { color: '#cf1322' }"
                >
                  <template #prefix>
                    <arrow-down-outlined v-if="statistic.todayViewIncreaseRate < 0"/>
                    <arrow-up-outlined v-if="statistic.todayViewIncreaseRate >= 0"/>
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <br>
      <a-row>
        <a-col :span="18">
          <a-row v-if="true">
            <a-col :span="24" id="main-col">
              <div id="main" style="width: 100%;height:300px;"></div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="6">
          <the-map style="margin: 50px 0 0 30px" />
        </a-col>
      </a-row>
    </div>

  </template>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import axios from 'axios';

import TheMap from "@/components/the-map.vue"

declare let echarts: any;

export default defineComponent({
  name: 'the-welcome',
  components: {
    TheMap
  },
  setup () {
    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    const statistic = ref();
    statistic.value = {};
    const getStatistic = () => {
      axios.get('/ebook-snapshot/get-statistic').then((response) => {
        const data = response.data;
        if (data.success) {
          const statisticResp = data.content;
          // Calculate the current time in minutes as a percentage of the day
          const now = new Date();
          const nowRate = (now.getHours() * 60 + now.getMinutes()) / (60 * 24);

          if (statisticResp[1]) {
            statistic.value.viewCount = statisticResp[1].viewCount;
            statistic.value.voteCount = statisticResp[1].voteCount;
            statistic.value.todayViewCount = statisticResp[1].viewIncrease;
            statistic.value.todayVoteCount = statisticResp[1].voteIncrease;
            statistic.value.todayViewIncrease = parseInt(String(statisticResp[1].viewIncrease / nowRate));
          } else {
            // statistic has only 1 piece of data
            statistic.value.viewCount = statisticResp[0].viewCount;
            statistic.value.voteCount = statisticResp[0].voteCount;
            statistic.value.todayViewCount = statisticResp[0].viewIncrease;
            statistic.value.todayVoteCount = statisticResp[0].voteIncrease;
            statistic.value.todayViewIncrease = parseInt(String(nowRate? statisticResp[0].viewIncrease / nowRate : 0));
          }

          // todayViewIncreaseRate：Expected Reading Growth
          statistic.value.todayViewIncreaseRate = statisticResp[0].viewIncrease? (statistic.value.todayViewIncrease - statisticResp[0].viewIncrease) / statisticResp[0].viewIncrease * 100 : 0;
          statistic.value.todayViewIncreaseRateAbs = Math.abs(statistic.value.todayViewIncreaseRate);
        }
      });
    };

    const init30DayEcharts = (list: any) => {
      const mainDom = document.getElementById('main-col');
      if (mainDom) {
        mainDom.innerHTML = '<div id="main" style="width: 100%;height:300px;"></div>';
      }

      const myChart = echarts.init(document.getElementById('main'));

      const xAxis = [];
      const seriesView = [];
      const seriesVote = [];
      for (let i = 0; i < list.length; i++) {
        const record = list[i];
        xAxis.push(record.date);
        seriesView.push(record.viewIncrease);
        seriesVote.push(record.voteIncrease);
      }

      const option = {
        title: {
          text: '30-day Trend Chart'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Total Reading', 'Total Likes']
        },
        grid: {
          left: '1%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Total Reading',
            type: 'line',
            data: seriesView,
            smooth: true
          },
          {
            name: 'Total Likes',
            type: 'line',
            data: seriesVote,
            smooth: true
          }
        ]
      };

      myChart.setOption(option);
    };

    const get30DayStatistic = () => {
      axios.get('/ebook-snapshot/get-30-statistic').then((response) => {
        const data = response.data;
        if (data.success) {
          const statisticList = data.content;

          init30DayEcharts(statisticList)
        }
      });
    };

    onMounted(() => {
      getStatistic();
      get30DayStatistic();
    });

    return {
      statistic,

      isMobile,
    }
  }
});
</script>

<style scoped>
.tip {
  padding: 10px 5px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  background: linear-gradient(white,white) padding-box,repeating-linear-gradient(-45deg, black 0, black 25%, white 0, white 50%) 0/.6em .6em;
  animation:ants 12s linear infinite;
}

.tip b {
  color: red;
}

.tip a {
  color: blue;
}
</style>