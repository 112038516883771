<template>
  <template v-if="isMobile">
    <a-layout-header class="header">
      <div class="m_logo" >Lei's Blog</div>
      <a-menu
          theme="dark"
          mode="horizontal"
          :style="{
            lineHeight: '64px',
            marginLeft: '20%',
          }"
      >
        <a-menu-item key="/">
          <router-link to="/">Homepage</router-link>
        </a-menu-item>

        <a-menu-item key="/about">
          <router-link to="/about">About Me</router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-header>
  </template>

  <template v-if="!isMobile">
    <a-layout-header class="header">
      <div class="logo" >Lei's Blog</div>
      <a-popconfirm
          title="Confirm logout?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="logout()"
          class="custom-popconfirm"
      >
        <a class="login-menu logout" v-show="user.id">
          <span>Logout</span>
        </a>
      </a-popconfirm>
        <a class="login-menu" v-show="user.id" @click="redirectToNotice" title="Go to notice board">
          <span>{{greeting}}: {{user.name}}</span>
        </a>
      <a class="login-menu" @click="showLoginModal" v-show="!user.id">
        <span>Login</span>
      </a>
      <a class="login-menu" @click="showRegisterModal" v-show="!user.id">
        <span>Register</span>
      </a>
      <a-menu
          theme="dark"
          mode="horizontal"
          :style="{
            lineHeight: '64px',
            marginLeft: '150px',
          }"
      >
        <a-menu-item key="/">
          <router-link to="/">Homepage</router-link>
        </a-menu-item>

        <a-menu-item key="/admin/user" v-if="user.id && userAuth">
          <router-link to="/admin/user">User Management</router-link>
        </a-menu-item>

        <a-menu-item key="/admin/ebook" v-if="user.id && userAuth">
          <router-link to="/admin/ebook">Ebook Management</router-link>
        </a-menu-item>

        <a-menu-item key="/admin/category" v-if="user.id && userAuth">
          <router-link to="/admin/category">Category Management</router-link>
        </a-menu-item>

        <a-menu-item key="/customer/user" v-if="user.id && !userAuth">
          <router-link to="/customer/user">Account Management</router-link>
        </a-menu-item>

        <a-menu-item key="/about" v-if="!userAuth">
          <router-link to="/about">About Me</router-link>
        </a-menu-item>
      </a-menu>

      <a-modal
          title="Login"
          v-model:visible="loginModalVisible"
          :confirm-loading="loginModalLoading"
          @ok="showImageCodeModal"
          okText="Login"
      >
        <a-form :model="loginUser" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="Username">
            <a-input v-model:value="loginUser.loginName" :maxlength="30" placeholder="Please enter username" />
          </a-form-item>
          <a-form-item label="Password">
            <a-input v-model:value="loginUser.password" :maxlength="30" type="password" placeholder="Please enter password" />
          </a-form-item>
        </a-form>
        <div style="display: flex; align-items: center; margin-left: 30px">
          <p style="margin-top: 10px;">Verification : </p>
          <a-input v-model:value="firstImageCodeTarget" :maxlength="4" style="margin-left: 8px; width: 350px;" placeholder="I am not a robot">
            <template #suffix>
              {{firstImageCodeSourceA}} + {{firstImageCodeSourceB}}
            </template>
          </a-input>
        </div>
        <div class="right-aligned-links">
          <p class="link-item" @click="onForgotUsername">Forgot Username?</p>
          <p class="link-item" @click="onForgotPassword">Forgot Password?</p>
        </div>
      </a-modal>

      <a-modal
          title="Register"
          v-model:visible="registerModalVisible"
          :confirm-loading="registerModalLoading"
          @ok="registerImageCodeModal"
          okText="Register"
      >
        <a-form :model="registerUser" :label-col="{ span: 8 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="Username">
            <a-input v-model:value="registerUser.loginName" :maxlength="30" placeholder="Please enter username" />
          </a-form-item>
          <a-form-item label="Nickname">
            <a-input v-model:value="registerUser.name" :maxlength="30" placeholder="Please enter nickname" />
          </a-form-item>
          <a-form-item label="Password">
            <a-input v-model:value="registerUser.password" type="password" :maxlength="30" placeholder="Please enter password" />
          </a-form-item>
          <a-form-item label="Confirmed Password">
            <a-input v-model:value="registerUser.confirmPassword" type="password" :maxlength="30" placeholder="Please enter password again" />
          </a-form-item>
          <a-form-item label="Email">
            <a-input v-model:value="registerUser.email"  :maxlength="50" placeholder="Please enter email" />
          </a-form-item>
          <a-form-item label="Invitation Code">
            <a-input v-model:value="registerUser.invite_code" :maxlength="20" placeholder="Please enter invitation code" />
          </a-form-item>
        </a-form>
        <div style="display: flex; align-items: center; margin-left: 69px">
          <p style="margin-top: 10px;">Verification : </p>
          <a-input v-model:value="firstImageCodeTarget" style="margin-left: 8px; width: 312px;" placeholder="I am not a robot">
            <template #suffix>
              {{firstImageCodeSourceA}} + {{firstImageCodeSourceB}}
            </template>
          </a-input>
        </div>
      </a-modal>

      <a-modal
          title="Retrieve Password"
          v-model:visible="retrievePasswordModalVisible"
          :confirm-loading="retrievePasswordModalLoading"
          @ok="handleRetrievePassword"
          okText="Confirm"
      >
        <a-form :model="retrievePasswordUser" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
          <a-form-item label="Username">
            <a-input v-model:value="retrievePasswordUser.login_name" :maxlength="30" placeholder="Please enter username" />
          </a-form-item>
          <a-form-item label="Email">
           <div class="email-button-container">
            <a-input v-model:value="retrievePasswordUser.email" :maxlength="30" placeholder="Please enter email" />
             <a-button type="primary" block :disabled="isRetrievePasswordButtonDisabled" @click="onSendCode" style="margin-left: 5px; width: 18%;">
               {{ isRetrievePasswordButtonDisabled ? countdownRetrievePasswordSeconds + 's' : 'Send' }}
             </a-button>
           </div>
          </a-form-item>
          <a-form-item label="Code">
            <a-input v-model:value="retrievePasswordUser.email_code" :maxlength="10" placeholder="Please enter verification code" />
          </a-form-item>
          <a-form-item label="New Password">
            <a-input v-model:value="retrievePasswordUser.password" :maxlength="30" type="password" placeholder="Please enter password" />
          </a-form-item>
          <a-form-item label="Confirm Password">
            <a-input v-model:value="retrievePasswordUser.confirmPassword" :maxlength="30" type="password" placeholder="Please enter password again" />
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
          title="Retrieve Username"
          v-model:visible="retrieveUsernameModalVisible"
          :confirm-loading="retrieveUsernameModalLoading"
          @ok="handleRetrieveUsername"
          okText="Confirm"
      >
        <a-form :model="retrieveUsernameUser" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-form-item label="Email">
            <div class="email-button-container">
              <a-input v-model:value="retrieveUsernameUser.email" :maxlength="30" placeholder="Please enter email" />
              <a-button type="primary" block :disabled="isRetrieveUsernameButtonDisabled" @click="onSendCodeForRetrieveUsername" style="margin-left: 5px; width: 18%;">
                {{ isRetrieveUsernameButtonDisabled ? countdownRetrieveUsernameSeconds + 's' : 'Send' }}
              </a-button>
            </div>
          </a-form-item>
          <a-form-item label="Code">
            <a-input v-model:value="retrieveUsernameUser.email_code" :maxlength="10" placeholder="Please enter verification code" />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-layout-header>

    <!--    Verification Code for Login -->
    <a-modal v-model:visible="imageCodeModalVisible" :title="null" :footer="null" :closable="false"
             style="top: 50px; width: 400px">
      <p style="text-align: center; font-weight: bold; font-size: 18px">
        Please enter verification code
      </p>
      <p>
        <a-input v-model:value="imageCode" :maxlength="10" placeholder="Verification Code">
          <template #suffix>
            <img v-show="!!imageCodeSrc" :src="imageCodeSrc" alt="kaptcha" v-on:click="loadImageCode()"/>
          </template>
        </a-input>
      </p>
      <a-button type="primary" danger block @click="login">Login</a-button>
    </a-modal>

    <!--    Email Verification for Register -->
    <a-modal v-model:visible="emailCodeModalVisible" :title="null" :footer="null" :closable="false"
             style="top: 50px; width: 400px">
      <p style="text-align: center; font-weight: bold; font-size: 18px">
        Please verify your email
      </p>
      <div style="display: flex; justify-content: space-between;">
        <a-input v-model:value="emailCode" placeholder="Email Verification" :maxlength="10" style="width: 80%;" />
        <a-button type="primary" block :disabled="isButtonDisabled" @click="sendEmailVerificationCode()" style="margin-left: 10px; width: 18%;">
          {{ isButtonDisabled ? countdownSeconds + 's' : 'Send' }}
        </a-button>
      </div>
      <a-button type="primary" danger block @click="register" style="margin-top: 10px">Register</a-button>
    </a-modal>

    <a-modal v-model:visible="usernamesListVisible" :title="null" :footer="null" :closable="false"
             style="top: 50px; width: 400px">
      <a-list
          bordered
          :data-source="usernames"
      >
        <template #renderItem="{ item }">
          <a-list-item>{{ item.login_name }}</a-list-item>
        </template>
        <template #header>
          <div style="font-weight: bold;">Usernames Linked to your email:</div>
        </template>
      </a-list>
      <a-button type="primary" block style="margin-top: 10px;" @click="hideList">Close</a-button>
    </a-modal>
  </template>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import axios from 'axios';
import { message, notification } from 'ant-design-vue';
import store from "@/store";
import router from "@/router";
import { Tool } from '@/util/tool';

declare let hexMd5: any;
declare let KEY: any;


export default defineComponent({
  name: 'the-header',

  setup () {
    const user = computed(() => store.state.user);
    let greeting = "Hello: "

    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    const loginUser = ref({
      loginName: "test",
      password: "test",
      imageCodeToken: "",
      imageCode: ""
    })

    const registerUser = ref({
      name: "",
      loginName: "",
      password: "",
      confirmPassword: "",
      invite_code: "",
      email: "",
      email_code: ""
    })

    const retrievePasswordUser = ref({
      login_name: "",
      email: "",
      email_code: "",
      password: "",
      confirmPassword: "",
    })

    const retrieveUsernameUser = ref({
      email: "",
      email_code: ""
    })

    let userAuth;
    userAuth = store.state.user.loginName === "luleilulei";

    const imageCodeModalVisible = ref();
    const imageCodeToken = ref();
    const imageCodeSrc = ref();
    const imageCode = ref();

    const firstImageCodeSourceA = ref();
    const firstImageCodeSourceB = ref();
    const firstImageCodeTarget = ref();

    const loginModalVisible = ref(false);
    const loginModalLoading = ref(false);

    const showLoginModal = () => {
      loginModalVisible.value = true;
      loadFirstImageCode();
    };

    const login = () => {
      if (Tool.isEmpty(imageCode.value)) {
        notification.error({
          message: 'Error',
          description: 'Verification code cannot be empty'
        });
        return ;
      }

      loginModalLoading.value = true;

      loginUser.value.password = hexMd5(loginUser.value.password + KEY);
      // kaptcha params
      loginUser.value.imageCodeToken = imageCodeToken.value;
      loginUser.value.imageCode = imageCode.value;
      axios.post('/user/login', loginUser.value).then((response) => {
        loginModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          loginModalVisible.value = false;
          imageCodeModalVisible.value = false;
          message.success("Login successfully!");

          localStorage.setItem("username", data.content.username);

          store.commit("setUser", data.content);
          userAuth = store.state.user.loginName === "luleilulei";

          loginUser.value.loginName = "";
          loginUser.value.password = "";
          imageCode.value = "";
          firstImageCodeTarget.value = "";
          userAuth = store.state.user.loginName === "luleilulei";
        } else {
          message.error(data.message);
          imageCodeModalVisible.value = false;
          loadFirstImageCode();
          imageCode.value = "";
          firstImageCodeTarget.value = "";
        }
      });
    };

    const getGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 0 && currentHour < 12) {
        return "Good morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        return "Good afternoon";
      } else {
        return "Good evening";
      }
    }

    greeting = getGreeting();

    const logout = () => {
      axios.get('/user/logout/' + user.value.token).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Logout Successfully");
          store.commit("setUser", {});
          router.push('/');
        } else {
          message.error(data.message);
        }
      });
    };

    const loadImageCode = () => {
      imageCodeToken.value = Tool.uuid(8);
      imageCodeSrc.value = process.env.VUE_APP_SERVER + '/kaptcha/image-code/' + imageCodeToken.value;
    };

    const showImageCodeModal = () => {
      // loginName validation
      if (loginUser.value.loginName == null || loginUser.value.loginName.length === 0) {
        message.error("Username cannot be empty.");
        loadFirstImageCode();
        loginModalLoading.value = false;
        return;
      }

      // Password length validation
      if (loginUser.value.password.length < 6 || loginUser.value.password.length > 32) {
        message.error("Password must be between 6 and 32 characters.");
        loadFirstImageCode();
        loginModalLoading.value = false;
        return;
      }

      // Password contains both numbers and letters validation
      const hasNumber = /\d/.test(loginUser.value.password);
      const hasLetter = /[a-zA-Z]/.test(loginUser.value.password);
      if (!hasNumber || !hasLetter) {
        message.error("Password must contain both numbers and letters.");
        loadFirstImageCode();
        loginModalLoading.value = false;
        return;
      }

      if(!validFirstImageCode()) {
        message.error("Verification failed.");
        loginModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      loadImageCode();
      imageCodeModalVisible.value = true;
    };

    const loadFirstImageCode = () => {
      // range: 10-100
      firstImageCodeSourceA.value = Math.floor(Math.random() * 90 + 10);
      firstImageCodeSourceB.value = Math.floor(Math.random() * 90 + 10);
    };

    const validFirstImageCode = () => {
      if (parseInt(firstImageCodeTarget.value) === parseInt(firstImageCodeSourceA.value + firstImageCodeSourceB.value)) {
        return true;
      } else {
        notification.error({
          message: 'Error',
          description: "Verification code doesn't match"
        });
        return false;
      }
    };

    const registerModalVisible = ref(false);
    const registerModalLoading = ref(false);

    const showRegisterModal = () => {
      registerModalVisible.value = true;
      loadFirstImageCode();
    }

    const emailCodeModalVisible = ref();
    const emailCode = ref();
    const isButtonDisabled = ref(false);
    const countdownSeconds = ref(0);
    let countdownInterval: number | null = null;

    const sendEmailVerificationCode = () => {
      if (isButtonDisabled.value) {
        return;
      }

      axios.get('/send-msg/' + registerUser.value.email).then((response) => {
        registerModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isButtonDisabled.value = true;
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
      countdownSeconds.value = 120;
      countdownInterval = setInterval(() => {
        countdownSeconds.value--;
        if (countdownSeconds.value <= 0) {
          clearInterval(countdownInterval as number);
          isButtonDisabled.value = false;
        }
      }, 1000);
    }

    const registerImageCodeModal = () => {
      // username validation
      if (registerUser.value.loginName == null || registerUser.value.loginName.length === 0) {
        message.error("Username cannot be empty.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      // nickname validation
      if (registerUser.value.name == null || registerUser.value.name.length === 0) {
        message.error("Nickname cannot be empty.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      // Password length validation
      if (registerUser.value.password.length < 6 || registerUser.value.password.length > 32) {
        message.error("Password must be between 6 and 32 characters.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      // Password contains both numbers and letters validation
      const hasNumber = /\d/.test(registerUser.value.password);
      const hasLetter = /[a-zA-Z]/.test(registerUser.value.password);
      if (!hasNumber || !hasLetter) {
        message.error("Password must contain both numbers and letters.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      // confirmed password must be the same as password
      if (registerUser.value.confirmPassword !== registerUser.value.password) {
        message.error("Password doesn't match.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(registerUser.value.email)) {
        message.error("Please enter valid email.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      // invitation code validation
      if (registerUser.value.invite_code == null || registerUser.value.invite_code.length !== 15) {
        message.error("Invalid invitation code.");
        registerModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      if(!validFirstImageCode()) {
        message.error("Verification failed.");
        loginModalLoading.value = false;
        loadFirstImageCode();
        return;
      }

      emailCodeModalVisible.value = true;
      isButtonDisabled.value = false;
    }

    const register = () => {
      if (Tool.isEmpty(emailCode.value)) {
        notification.error({
          message: 'Error',
          description: 'Email verification failed'
        });
        return ;
      }

      registerModalLoading.value = true;

      registerUser.value.password = hexMd5(registerUser.value.password + KEY);
      registerUser.value.confirmPassword = hexMd5(registerUser.value.confirmPassword + KEY);
      registerUser.value.email_code = emailCode.value;

      axios.post('/user/register', registerUser.value).then((response) => {
        registerModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          registerModalVisible.value = false;
          message.success("Registered successfully!");

          store.commit("setUser", data.content);

          emailCodeModalVisible.value = false;

          registerUser.value.loginName = "";
          registerUser.value.name = "";
          registerUser.value.password = "";
          registerUser.value.confirmPassword = "";
          registerUser.value.email = "";
          registerUser.value.invite_code = "";
          imageCode.value = "";
          firstImageCodeTarget.value = "";
          emailCode.value = "";
        } else {
          message.error(data.message);
          loadFirstImageCode();
          registerUser.value.loginName = "";
          registerUser.value.name = "";
          registerUser.value.password = "";
          registerUser.value.confirmPassword = "";
          registerUser.value.email = "";
          registerUser.value.invite_code = "";
          imageCode.value = "";
          firstImageCodeTarget.value = "";
          emailCode.value = "";
        }
      })
    }

    const retrievePasswordModalVisible = ref(false);
    const retrievePasswordModalLoading = ref(false);

    const onForgotPassword = () => {
      loginModalVisible.value = false;
      retrievePasswordModalVisible.value = true;
    }

    const isRetrievePasswordButtonDisabled = ref(false);
    const countdownRetrievePasswordSeconds = ref(0);
    let countdownRetrievePasswordInterval: number | null = null;

    const onSendCode = () => {
      if (isRetrievePasswordButtonDisabled.value) {
        return;
      }

      if (!Tool.checkEmail(retrievePasswordUser.value.email)){
        message.error("Invalid email format.");
        return ;
      }

      if (countdownRetrievePasswordInterval) {
        clearInterval(countdownRetrievePasswordInterval);
      }

      axios.get('/send-msg/' + retrievePasswordUser.value.email).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isRetrievePasswordButtonDisabled.value = true;
      if (countdownRetrievePasswordInterval) {
        clearInterval(countdownRetrievePasswordInterval);
      }
      countdownRetrievePasswordSeconds.value = 120;
      countdownRetrievePasswordInterval = setInterval(() => {
        countdownRetrievePasswordSeconds.value--;
        if (countdownRetrievePasswordSeconds.value <= 0) {
          clearInterval(countdownRetrievePasswordInterval as number);
          isRetrievePasswordButtonDisabled.value = false;
        }
      }, 1000);
    }

    const handleRetrievePassword = () => {
      // check username
      if (retrievePasswordUser.value.login_name == null || retrievePasswordUser.value.login_name.length === 0) {
        message.error("Username cannot be empty.");
        retrievePasswordModalLoading.value = false;
        return;
      }

      // check email
      if (!Tool.checkEmail(retrievePasswordUser.value.email)){
        message.error("Invalid email format.");
        retrievePasswordModalLoading.value = false;
        return ;
      }

      // check code
      if (retrievePasswordUser.value.email_code.length < 5 || retrievePasswordUser.value.email_code.length > 10){
        message.error("Invalid email verification code.");
        retrievePasswordModalLoading.value = false;
        return ;
      }

      // check password
      if (!Tool.checkPassword(retrievePasswordUser.value.password)){
        message.error("Invalid password.");
        retrievePasswordModalLoading.value = false;
        return ;
      }

      // check confirm_password
      if (!Tool.checkPassword(retrievePasswordUser.value.confirmPassword)){
        message.error("Invalid confirm password.");
        retrievePasswordModalLoading.value = false;
        return ;
      }

      // confirm_password must be same as password
      if (retrievePasswordUser.value.confirmPassword !== retrievePasswordUser.value.password){
        message.error("Password doesn't match.");
        retrievePasswordModalLoading.value = false;
        return ;
      }

      retrievePasswordModalLoading.value = true;
      retrievePasswordUser.value.password = hexMd5(retrievePasswordUser.value.password + KEY);

      axios.post('/user/find-password', retrievePasswordUser.value).then((response) => {
        retrievePasswordModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          message.success("Retrieve account successfully!");

          retrievePasswordModalVisible.value = false;

          retrievePasswordUser.value.login_name = "";
          retrievePasswordUser.value.email = "";
          retrievePasswordUser.value.email_code = "";
          retrievePasswordUser.value.password = "";
          retrievePasswordUser.value.confirmPassword = "";
        } else {
          message.error(data.message);
          retrievePasswordUser.value.login_name = "";
          retrievePasswordUser.value.email = "";
          retrievePasswordUser.value.email_code = "";
          retrievePasswordUser.value.password = "";
          retrievePasswordUser.value.confirmPassword = "";
        }
      })
    }

    const retrieveUsernameModalVisible = ref(false);
    const retrieveUsernameModalLoading = ref(false);

    const onForgotUsername = () => {
      loginModalVisible.value = false;
      retrieveUsernameModalVisible.value = true;
    }

    const isRetrieveUsernameButtonDisabled = ref(false);
    const countdownRetrieveUsernameSeconds = ref(0);
    let countdownRetrieveUsernameInterval: number | null = null;

    const onSendCodeForRetrieveUsername = () => {
      if (isRetrieveUsernameButtonDisabled.value) {
        return;
      }

      if (!Tool.checkEmail(retrieveUsernameUser.value.email)){
        message.error("Invalid email format.");
        return ;
      }

      if (countdownRetrieveUsernameInterval) {
        clearInterval(countdownRetrieveUsernameInterval);
      }

      axios.get('/send-msg/' + retrieveUsernameUser.value.email).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isRetrieveUsernameButtonDisabled.value = true;
      if (countdownRetrieveUsernameInterval) {
        clearInterval(countdownRetrieveUsernameInterval);
      }
      countdownRetrieveUsernameSeconds.value = 120;
      countdownRetrieveUsernameInterval = setInterval(() => {
        countdownRetrieveUsernameSeconds.value--;
        if (countdownRetrieveUsernameSeconds.value <= 0) {
          clearInterval(countdownRetrieveUsernameInterval as number);
          isRetrieveUsernameButtonDisabled.value = false;
        }
      }, 1000);
    }

    const usernamesListVisible = ref(false);
    const usernames = ref();

    const hideList = () => {
      usernamesListVisible.value = false;
    }

    const handleRetrieveUsername = () => {
      // check email
      if (!Tool.checkEmail(retrieveUsernameUser.value.email)){
        message.error("Invalid email format.");
        retrieveUsernameModalLoading.value = false;
        return ;
      }

      // check code
      if (retrieveUsernameUser.value.email_code.length < 5 || retrieveUsernameUser.value.email_code.length > 10){
        message.error("Invalid email verification code.");
        retrieveUsernameModalLoading.value = false;
        return ;
      }

      axios.post('/user/find-username', retrieveUsernameUser.value).then((response) => {
        retrievePasswordModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          message.success("Retrieve account successfully!");

          retrieveUsernameModalVisible.value = false;
          usernames.value = data.content;
          usernamesListVisible.value = true;

          retrieveUsernameUser.value.email = "";
          retrieveUsernameUser.value.email_code = "";
        } else {
          message.error(data.message);
          retrieveUsernameUser.value.email = "";
          retrieveUsernameUser.value.email_code = "";
        }
      })
    }

    const redirectToNotice = () => {
      router.push('/customer/notice');
    };

    onMounted(() => {
      userAuth = store.state.user.loginName === "luleilulei";
    });

    return {
      loginModalVisible,
      loginModalLoading,
      showLoginModal,
      loginUser,
      login,

      user,
      greeting,

      logout,

      isMobile,

      imageCodeToken,
      imageCodeSrc,
      imageCode,
      showImageCodeModal,
      imageCodeModalVisible,
      loadImageCode,

      firstImageCodeSourceA,
      firstImageCodeSourceB,
      firstImageCodeTarget,

      registerModalVisible,
      registerModalLoading,
      register,
      registerUser,
      showRegisterModal,
      registerImageCodeModal,

      emailCodeModalVisible,
      sendEmailVerificationCode,
      emailCode,
      isButtonDisabled,
      countdownSeconds,

      userAuth,

      onForgotPassword,
      retrievePasswordUser,
      retrievePasswordModalVisible,
      retrievePasswordModalLoading,
      handleRetrievePassword,
      onSendCode,
      isRetrievePasswordButtonDisabled,
      countdownRetrievePasswordSeconds,

      onForgotUsername,
      retrieveUsernameModalVisible,
      retrieveUsernameModalLoading,
      isRetrieveUsernameButtonDisabled,
      countdownRetrieveUsernameSeconds,
      onSendCodeForRetrieveUsername,
      retrieveUsernameUser,
      handleRetrieveUsername,
      usernamesListVisible,
      usernames,
      hideList,

      redirectToNotice
    }
  }
});
</script>

<style>
.header {
  user-select: none;
}

.logo {
  width: 120px;
  height: 31px;
  /*background: rgba(255, 255, 255, 0.2);*/
  /*margin: 16px 28px 16px 0;*/
  float: left;
  color: white;
  font-size: 18px;
}

.m_logo {
  margin-left: -20px;
  width: 80px;
  height: 31px;
  float: left;
  color: white;
  font-size: 15px;
}

.login-menu {
  float: right;
  color: white;
  padding-left: 30px;
}

.logout {
  color: indianred;
}

.custom-popconfirm {
  width: 100px;
}

.right-aligned-links {
  display: flex;
  justify-content: flex-end;
}

.link-item {
  font-size: 0.8em;
  margin-left: 15px;
  color: blue;
  cursor: pointer;
  user-select: none;
}

.email-button-container {
  display: flex;
  justify-content: flex-end;
}
</style>