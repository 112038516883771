<template>
  <a-layout>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '610px' }"
    >
      <p>
        <a-form layout="inline" :model="param">
          <a-form-item>
            <a-input v-model:value="param.loginName" placeholder="Username">
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleQuery({page: 1, size: pagination.pageSize})">
              Search
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add()">
              Add New
            </a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="addInvitationCode()">
              Create Invitation Code
            </a-button>
          </a-form-item>
        </a-form>
      </p>
      <a-table
          :columns="columns"
          :row-key="record => record.id"
          :data-source="users"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
      >
        <template v-slot:action="{ text, record }">
          <a-space size="small">
            <a-button type="primary" @click="resetPassword(record)">
              Reset Password
            </a-button>
            <a-button type="primary" @click="edit(record)">
              Modify
            </a-button>
            <a-button type="dashed" :loading="priorModalLoading[record.id]" @click="prior(record)">
              Member
            </a-button>
            <a-button type="dashed" :loading="jinyanBtnLoading[record.id]" @click="jinyan(record)">
              Jinyan
            </a-button>
            <a-popconfirm
                title="Are you sure delete this task?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleDelete(record.id)"
            >
              <a-button type="primary" danger>
                Delete
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>

  <a-modal
      title="User Form"
      v-model:visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Username">
        <a-input v-model:value="user.loginName" :disabled="!!user.id"/>
      </a-form-item>
      <a-form-item label="Nickname">
        <a-input v-model:value="user.name" />
      </a-form-item>
      <a-form-item label="Password" v-show="!user.id">
        <a-input v-model:value="user.password" type="password"/>
      </a-form-item>
      <a-form-item label="Email">
        <a-input v-model:value="user.email"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
      title="Reset Password"
      v-model:visible="resetModalVisible"
      :confirm-loading="resetModalLoading"
      @ok="handleResetModalOk"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="New Password">
        <a-input v-model:value="user.password" type="password"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
      title="Generate Invitation Code"
      v-model:visible="inviteModalVisible"
      :confirm-loading="inviteModalLoading"
      @ok="handleInviteModalOk"
      okText="Quit"
  >
    <p style="font-size: 16px; font-weight: bold;">{{ invitation_code }}</p>
    <p style="color: red;">Please keep the invitation code properly</p>
    <p v-if="countdown > 0">This modal will be closed after second <span class="countdown-number">{{ countdown }}</span> seconds.</p>
    <a-button type="dashed"  size="small" style="width: 100px" icon="Copy" v-on:click="handleSaveCode" />
  </a-modal>

  <a-modal
      title="Modify Priority"
      v-model:visible="priorModalVisible"
      :confirm-loading="priorOKLoading"
      @ok="handlePriorModalOk"
      :vip_user="vip_user"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Membership Level">
        <div :style="{ marginLeft: '20px' }">
          <a-checkbox v-model:checked="booked">
            {{ label }}
          </a-checkbox>
          <a-button
              style="margin-left: 20px"
              type="primary"
              size="small"
              :loading="toggleVipBtnLoading"
              @click="toggleBooked(vip_user)"
          >
            {{ !booked ? 'Set' : 'Unset' }}
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="Membership Time">
        <div>
          <a-date-picker
              style="margin-left: 20px"
              v-model:value="timePeriodSelected"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
          />
        </div>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
      title="Jinyan Board"
      v-model:visible="jinyanModalVisible"
      :confirm-loading="jinyanOKLoading"
      @ok="handleJinyanModalOk"
      :interdiction_user="interdictioner"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Jinyan?">
        <div :style="{ marginLeft: '20px' }">
          <a-checkbox v-model:checked="jinyaned">
            {{ jinyan_label }}
          </a-checkbox>
          <a-button
              style="margin-left: 20px"
              type="primary"
              size="small"
              :loading="toggleJinyanBtnLoading"
              @click="toggleJinyan"
          >
            {{ !jinyaned ? 'Set' : 'Unset' }}
          </a-button>
        </div>
      </a-form-item>
      <a-form-item label="Jinyan Time">
        <div>
          <a-date-picker
              style="margin-left: 20px"
              v-model:value="jinyanPeriodSelected"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
          />
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import { message, notification } from 'ant-design-vue';
import {Tool} from "@/util/tool";
import store from "@/store";
import dayjs, { Dayjs } from 'dayjs';

declare let hexMd5: any;
declare let KEY: any;

export default defineComponent({
  name: 'AdminUser',
  setup() {
    const param = ref();
    param.value = {};
    const users = ref();
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0
    });
    const loading = ref(false);

    const columns = [
      {
        title: 'Username',
        dataIndex: 'loginName'
      },
      {
        title: 'Nickname',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Password',
        dataIndex: 'password'
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ];

    const handleQuery = (params: any) => {
      loading.value = true;
      users.value = [];
      axios.get("/user/list", {
        params: {
          page: params.page,
          size: params.size,
          loginName: param.value.loginName
        }
      }).then((response) => {
        loading.value = false;
        const data = response.data;
        if (data.success) {
          users.value = data.content.list;

          pagination.value.current = params.page;
          pagination.value.total = data.content.total;
        } else {
          message.error(data.message);
        }
      });
    };


    const handleTableChange = (pagination: any) => {
      handleQuery({
        page: pagination.current,
        size: pagination.pageSize
      });
    };

    // -------- Form ---------
    const user = ref();
    const modalVisible = ref(false);
    const modalLoading = ref(false);
    const handleModalOk = () => {
      modalLoading.value = true;

      // md5
      user.value.password = hexMd5(user.value.password + KEY);

      axios.post("/user/save", user.value).then((response) => {
        modalLoading.value = false;
        const data = response.data;
        if (data.success) {
          modalVisible.value = false;

          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize,
          });
        } else {
          message.error(data.message);
        }
      });
    };

    const edit = (record: any) => {
      modalVisible.value = true;
      user.value = Tool.copy(record);
    };

    const add = () => {
      modalVisible.value = true;
      user.value = {};
    };

    const handleDelete = (id: number) => {
      axios.delete("/user/delete/" + id).then((response) => {
        const data = response.data;
        if (data.success) {
          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize,
          });
        }
      });
    };

    // -------- Reset Password ---------
    const resetModalVisible = ref(false);
    const resetModalLoading = ref(false);
    const handleResetModalOk = () => {
      resetModalLoading.value = true;

      user.value.password = hexMd5(user.value.password + KEY);

      axios.post("/user/reset-password", user.value).then((response) => {
        resetModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          resetModalVisible.value = false;

          handleQuery({
            page: pagination.value.current,
            size: pagination.value.pageSize,
          });
        } else {
          message.error(data.message);
        }
      });
    };

    const resetPassword = (record: any) => {
      resetModalVisible.value = true;
      user.value = Tool.copy(record);
      user.value.password = null;
    };

    const inviteModalVisible = ref(false);
    const inviteModalLoading = ref(false);
    const invitation_code = ref();
    const countdown = ref(10);

    let timer: number | null = null;

    const startCountdown = () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }

      countdown.value = 10;
      timer = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          inviteModalVisible.value = false;
          countdown.value = 10;
          if (timer) {
            clearInterval(timer);
            timer = null;
          }
        }
      }, 1000);
    }

    const addInvitationCode = () => {
      inviteModalVisible.value = true;
      invitation_code.value = "";
      axios.get("/invite-code").then((response) => {
        inviteModalLoading.value = false;
        invitation_code.value = response.data.content;
      });

      startCountdown();
    }

    const handleInviteModalOk = () => {
      inviteModalVisible.value = false;
    }

    const handleSaveCode = () => {
      // Create temporary elements
      const tempElem = document.createElement('textarea');
      tempElem.value = invitation_code.value || "";
      // Add temporary elements to the DOM
      document.body.appendChild(tempElem);
      // Select the content of the temporary element
      tempElem.select();
      // Compatible with mobile devices
      tempElem.setSelectionRange(0, 99999);
      // Copy selected content to clipboard
      document.execCommand('copy');
      // Remove temporary elements
      document.body.removeChild(tempElem);
    }

    const booked = ref(false);
    const priorModalVisible = ref(false);
    const priorModalLoading = ref<Record<string, boolean>>({});
    const priorOKLoading = ref(false);
    const timePeriodSelected = ref<Dayjs>();
    const getVipInfoReq = ref({
      id : "",
      loginName: ""
    })
    const vip_user = ref({
      id : "",
      loginName: ""
    })

    const prior = (record: any) => {
      priorModalLoading.value[record.id] = true;
      getVipInfoReq.value.id = record.id;
      getVipInfoReq.value.loginName = record.loginName;
      vip_user.value.id = record.id;
      vip_user.value.loginName = record.loginName;
      axios.post("/vvip/get", getVipInfoReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        priorModalLoading.value[record.id] = false;
        priorModalVisible.value = true;
        const data = response.data;
        getVipInfoReq.value.id = "";
        getVipInfoReq.value.loginName = "";
        if (data.success) {
          if (dayjs(data.message, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
            timePeriodSelected.value = dayjs(data.message, 'YYYY-MM-DD HH:mm:ss');
          } else {
            timePeriodSelected.value = dayjs('1997-09-15 12:40:00');
          }
          booked.value = data.content;
        } else {
          message.error(data.message);
        }
      });
    }

    const toggleVipBtnLoading = ref(false);
    const vipToggleReq = ref({
      id : "",
      loginName: "",
      booked: false
    })

    const toggleBooked = (vip_user: any) => {
      vipToggleReq.value.id = vip_user.id;
      vipToggleReq.value.loginName = vip_user.loginName;
      vipToggleReq.value.booked = !booked.value;
      toggleVipBtnLoading.value = true;

      axios.post("/vvip/toggle", vipToggleReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        toggleVipBtnLoading.value = false;
        const data = response.data;
        vipToggleReq.value.id = "";
        vipToggleReq.value.loginName = "";
        vipToggleReq.value.booked = false;
        if (data.success) {
          booked.value = !booked.value;
        } else {
          message.error(data.message);
        }
      });
    }

    const label = computed(() => {
      return `${booked.value ? 'VIP' : 'non-VIP'}`;
    });

    const vipReselectReq = ref({
      id : "",
      loginName: "",
      booked: false,
      date: ""
    })

    const handlePriorModalOk = () => {
      vipReselectReq.value.id = vip_user.value.id;
      vipReselectReq.value.loginName = vip_user.value.loginName;
      vipReselectReq.value.booked = booked.value;
      vipReselectReq.value.date = timePeriodSelected.value?.format('YYYY-MM-DD HH:mm:ss') || "";
      priorOKLoading.value = true;

      axios.post("/vvip/reselect", vipReselectReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        vipReselectReq.value.id = "";
        vipReselectReq.value.loginName = "";
        vipReselectReq.value.booked = false;
        vipReselectReq.value.date = "";
        if (data.success) {
          console.log(data.content)
          if(!response.data.content){
            notification.open({
              message: 'Notification',
              description:
                  'User ' + vip_user.value.loginName + ' is not a VIP',
            });
          }
        } else {
          message.error(data.message);
        }

        priorOKLoading.value = false;
        priorModalVisible.value = false;
      });
    }

    const jinyanBtnLoading = ref<Record<string, boolean>>({});
    const jinyaned = ref(false);
    const jinyanModalVisible = ref(false);
    const jinyanPeriodSelected = ref<Dayjs>();

    const getJinyanInfoReq = ref({
      id: "",
      loginName: ""
    })

    const interdictioner = ref({
      id: "",
      loginName: ""
    })

    const jinyan = (record: any) => {
      jinyanBtnLoading.value[record.id] = true;
      getJinyanInfoReq.value.id = record.id;
      getJinyanInfoReq.value.loginName = record.loginName;
      interdictioner.value.id = record.id;
      interdictioner.value.loginName = record.loginName;
      axios.post("/jinyanl/get", getJinyanInfoReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        jinyanBtnLoading.value[record.id] = false;
        jinyanModalVisible.value = true;
        const data = response.data;
        getJinyanInfoReq.value.id = "";
        getJinyanInfoReq.value.loginName = "";
        if (data.success) {
          if (dayjs(data.message, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
            jinyanPeriodSelected.value = dayjs(data.message, 'YYYY-MM-DD HH:mm:ss');
          } else {
            jinyanPeriodSelected.value = dayjs('1997-09-15 12:40:00');
          }
          jinyaned.value = data.content;
        } else {
          message.error(data.message);
        }
      });
    }

    const jinyan_label = computed(() => {
      return `${jinyaned.value ? 'Yes' : 'No'}`;
    });

    const toggleJinyanBtnLoading = ref(false);
    const jinyanToggleReq = ref({
      id : "",
      loginName: "",
      interdiction: false
    })

    const toggleJinyan = () => {
      console.log(interdictioner.value)
      jinyanToggleReq.value.id = interdictioner.value.id;
      jinyanToggleReq.value.loginName = interdictioner.value.loginName;
      jinyanToggleReq.value.interdiction = !jinyaned.value;
      toggleJinyanBtnLoading.value = true;

      axios.post("/jinyanl/toggle", jinyanToggleReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        toggleJinyanBtnLoading.value = false;
        const data = response.data;
        jinyanToggleReq.value.id = "";
        jinyanToggleReq.value.loginName = "";
        jinyanToggleReq.value.interdiction = false;
        if (data.success) {
          jinyaned.value = !jinyaned.value;
        } else {
          message.error(data.message);
        }
      });
    }

    const jinyanOKLoading = ref(false);
    const jinyanReselectReq = ref({
      id : "",
      loginName: "",
      interdiction: false,
      date: ""
    })

    const handleJinyanModalOk = () => {
      jinyanReselectReq.value.id = interdictioner.value.id;
      jinyanReselectReq.value.loginName = interdictioner.value.loginName;
      jinyanReselectReq.value.interdiction = booked.value;
      jinyanReselectReq.value.date = jinyanPeriodSelected.value?.format('YYYY-MM-DD HH:mm:ss') || "";
      jinyanOKLoading.value = true;

      axios.post("/jinyanl/reselect", jinyanReselectReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        jinyanReselectReq.value.id = "";
        jinyanReselectReq.value.loginName = "";
        jinyanReselectReq.value.interdiction = false;
        jinyanReselectReq.value.date = "";
        if (data.success) {
          if(!response.data.content){
            notification.open({
              message: 'Notification',
              description:
                  'User ' + interdictioner.value.loginName + ' is not in jinyan state',
            });
          }
        } else {
          message.error(data.message);
        }

        jinyanOKLoading.value = false;
        jinyanModalVisible.value = false;
      });
    }

    onMounted(() => {
      handleQuery({
        page: 1,
        size: pagination.value.pageSize,
      });
    });

    return {
      param,
      users,
      pagination,
      columns,
      loading,
      handleTableChange,
      handleQuery,

      edit,
      add,

      user,
      modalVisible,
      modalLoading,
      handleModalOk,

      handleDelete,

      resetModalVisible,
      resetModalLoading,
      handleResetModalOk,
      resetPassword,

      addInvitationCode,
      inviteModalVisible,
      inviteModalLoading,
      invitation_code,
      handleInviteModalOk,
      handleSaveCode,

      countdown,

      booked,
      prior,
      priorModalVisible,
      priorModalLoading,
      handlePriorModalOk,
      toggleBooked,
      priorOKLoading,
      label,
      vip_user,
      toggleVipBtnLoading,

      dayjs,
      timePeriodSelected,
      jinyanPeriodSelected,

      jinyanBtnLoading,
      jinyanModalVisible,
      jinyaned,
      jinyan,
      interdictioner,
      jinyan_label,
      toggleJinyan,
      toggleJinyanBtnLoading,
      jinyanOKLoading,
      handleJinyanModalOk,
    }
  }
});
</script>

<style scoped>
img {
  width: 50px;
  height: 50px;
}

.countdown-number {
  color: red;
  font-weight: bold;
}
</style>

