import {message} from "ant-design-vue";

export class Tool {
    /**
     * Empty Check:  return true if null or ""
     */
    public static isEmpty (obj: any) {
        if ((typeof obj === 'string')) {
            return !obj || obj.replace(/\s+/g, "") === ""
        } else {
            return (!obj || JSON.stringify(obj) === "{}" || obj.length === 0);
        }
    }

    /**
     * NonEmpty Check
     */
    public static isNotEmpty (obj: any) {
        return !this.isEmpty(obj);
    }

    /**
     * Copy Object
     * @param obj
     */
    public static copy (obj: object) {
        if (Tool.isNotEmpty(obj)) {
            return JSON.parse(JSON.stringify(obj));
        }
    }

    /**
     * Convert array to tree structure using recursion
     */
    public static array2Tree (array: any, parentId: number) {
        if (Tool.isEmpty(array)) {
            return [];
        }

        const result = [];
        for (let i = 0; i < array.length; i++) {
            const c = array[i];
            if (Number(c.parent) === Number(parentId)) {
                result.push(c);

                // Recursively view the child nodes corresponding to the current node
                const children = Tool.array2Tree(array, c.id);
                if (Tool.isNotEmpty(children)) {
                    c.children = children;
                }
            }
        }
        return result;
    }

    /**
     * Randomly generate a [radix] base number of length [len]
     * @param len
     * @param radix default by 62
     * @returns {string}
     */
    public static uuid (len: number, radix = 62) {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        const uuid = [];
        radix = radix || chars.length;

        for (let i = 0; i < len; i++) {
            uuid[i] = chars[0 | Math.random() * radix];
        }

        return uuid.join('');
    }

    /**
     * Randomly check valid email format
     * @param string
     * @returns {boolean}
     */
    public static checkEmail (email: string) {
        const emailRegex = /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+$/;
        return emailRegex.test(email);
    }

    /**
     * Randomly check valid password format
     * @param string
     * @returns {boolean}
     */
    public static checkPassword (password: string) {
        if (password.length < 6 || password.length > 32) {
            return false;
        }

        const hasNumber = /\d/.test(password);
        const hasLetter = /[a-zA-Z]/.test(password);
        if (!hasNumber || !hasLetter) {
            return false;
        }

        return true;
    }

    /**
     * truncate a string
     * @param string
     * @param int
     * @returns {string}
     */
    public static truncateString (str: string, length: number) {
        if (str.length <= length) {
            return str;
        } else {
            return str.substring(0, length) + "...";
        }
    }
}