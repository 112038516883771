import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86cfe134"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, { style: { background: '#fff', padding: '24px', margin: 0, minHeight: '280px' } }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createVNode(_component_a_form, {
                layout: "inline",
                model: _ctx.param
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        value: _ctx.param.name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.param.name) = $event)),
                        placeholder: "Please Enter Name As Key Word",
                        size: "large",
                        style: {"width":"300px"}
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSearch({page: 1, size: _ctx.pagination.pageSize}))),
                        size: "large"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Search ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.add())),
                        size: "large"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Add New ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createElementVNode("p", null, [
              _createVNode(_component_a_alert, {
                class: "tip",
                message: " Tip: The categories here will be displayed in the sidebar menu on the homepage.",
                type: "info",
                closable: ""
              })
            ]),
            (_ctx.level1.length > 0)
              ? (_openBlock(), _createBlock(_component_a_table, {
                  key: 0,
                  columns: _ctx.columns,
                  "row-key": record => record.id,
                  "data-source": _ctx.level1,
                  pagination: false,
                  loading: _ctx.loading,
                  defaultExpandAllRows: true
                }, {
                  cover: _withCtx(({ text: cover }) => [
                    cover
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: cover,
                          alt: "avatar"
                        }, null, 8, _hoisted_1))
                      : _createCommentVNode("", true)
                  ]),
                  action: _withCtx(({ text, record }) => [
                    _createVNode(_component_a_space, { size: "small" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: ($event: any) => (_ctx.edit(record))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Modify ")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_a_popconfirm, {
                          title: "Are you sure delete this task?",
                          "ok-text": "Yes",
                          "cancel-text": "No",
                          onConfirm: ($event: any) => (_ctx.handleDelete(record.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Delete ")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["columns", "row-key", "data-source", "loading"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      title: "Category Form",
      visible: _ctx.modalVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalVisible) = $event)),
      "confirm-loading": _ctx.modalLoading,
      onOk: _ctx.handleModalOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.category,
          "label-col": { span: 6 },
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "Name" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.category.name,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.category.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "Parent ID" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.category.parent,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.category.parent) = $event)),
                  ref: "select"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select_option, { value: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(" None ")
                      ]),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.level1, (c) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: c.id,
                        value: c.id,
                        disabled: _ctx.category.id === c.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(c.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { label: "Order" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.category.sort,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.category.sort) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "confirm-loading", "onOk"])
  ], 64))
}