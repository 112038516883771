<template>
  <a-layout>
    <a-layout-sider :width="isMobile ? '40%' : '200'" style="background: #fff">
        <a-menu
            mode="inline"
            :style="{ height: '100%', borderRight: 0 }"
            @click="handleClick"
            :openKeys="openKeys"
        >
          <a-menu-item key="welcome">
            <GlobalOutlined />
            <span>Welcome</span>
          </a-menu-item>
          <a-sub-menu v-for="item in level1" :key="item.id" :disabled="true">
            <template v-slot:title>
              <span><ApartmentOutlined />&#32;{{ item.name}}</span>
            </template>
            <a-menu-item v-for="child in item.children" :key="child.id">
              <MailOutlined /><span>{{child.name}}</span>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="tip" :disabled="true" title="The menus above can be configured in the category management.">
            <span v-if="!isMobile">The menus above can be configured in the category management.</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
    <a-layout-content
          :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
      >
      <div class="welcome" v-show="isShowWelcome">
        <the-welcome></the-welcome>
      </div>
      <a-list v-if="!isMobile" v-show="!isShowWelcome" item-layout="vertical" size="large" :grid="{ gutter: 20, column: 3 }" :pagination="pagination" :data-source="ebooks">
        <template #renderItem="{ item }">
          <a-list-item key="item.name">
            <template #actions>
          <span>
                <component v-bind:is="'FileOutlined'" style="margin-right: 8px" />
                {{ item.docCount }}
              </span>
              <span>
                <component v-bind:is="'UserOutlined'" style="margin-right: 8px" />
                {{ item.viewCount }}
              </span>
              <span>
                <component v-bind:is="'LikeOutlined'" style="margin-right: 8px" />
                {{ item.voteCount }}
              </span>
            </template>
            <a-list-item-meta :description="item.description">
              <template #title>
                <router-link :to="'/doc?ebookId=' + item.id">
                  {{ item.name }}
                </router-link>
              </template>
              <template #avatar><a-avatar :src="item.cover" /></template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>

      <a-list v-if="isMobile" v-show="!isShowWelcome" item-layout="vertical" size="large" :grid="{ gutter: 20, column: 1 }" :pagination="pagination" :data-source="ebooks">
        <template #renderItem="{ item }">
          <a-list-item key="item.name">
            <a-list-item-meta :description="item.description">
              <template #title>
                <router-link :to="'/doc?ebookId=' + item.id">
                  {{ item.name }}
                </router-link>
              </template>
              <template #avatar><a-avatar :src="item.cover" /></template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      </a-layout-content>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import TheWelcome from "@/components/the-welcome.vue"

import { message } from 'ant-design-vue';
import {Tool} from "@/util/tool";

export default defineComponent({
  name: 'HomeView',
  components: {
    TheWelcome
  },
  setup() {

    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    const ebooks = ref();
    const openKeys =  ref();
    const level1 =  ref();
    let categories: any;

    const handleQueryCategory = () => {
      axios.get("/category/all").then((response) => {
        const data = response.data;
        if (data.success) {
          categories = data.content;

          // unfold the sidebar
          openKeys.value = [];
          for (let i = 0; i < categories.length; i++) {
            openKeys.value.push(categories[i].id)
          }

          level1.value = [];
          level1.value = Tool.array2Tree(categories, 0);
        } else {
          message.error(data.message);
        }
      });
    };

    const handleQueryEbook = () => {
      axios.get("/ebook/list", {
        params: {
          page: 1,
          size: 1000,
          categoryId2: categoryId2
        }
      }).then((response) => {
        const data = response.data;
        ebooks.value = data.content.list;
      });
    }

    const isShowWelcome = ref(true);
    let categoryId2 = 0;

    const handleClick = (value: any) => {
      if (value.key === 'welcome') {
        isShowWelcome.value = true;
      } else {
        categoryId2 = value.key;
        isShowWelcome.value = false;
        handleQueryEbook();
      }
    };

    onMounted(() => {
      handleQueryCategory();
    });

    return {
      ebooks,
      pagination  : {
        onChange: (page: any) => {
          // console.log(page);
        },
        pageSize: 12,
      },

      handleClick,
      level1,

      isShowWelcome,

      openKeys,

      isMobile
    }
  }
});
</script>
