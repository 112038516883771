<template>
  <a-button type="primary" @click="showDrawer">
    <QuestionCircleOutlined />
    Contact Administrator
  </a-button>
  <a-drawer
      title="Contact Administrator"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
  >
    <a-form :model="form" :rules="rules" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="Type" name="type">
            <a-select placeholder="Please choose a type" v-model:value="form.type">
              <a-select-option value="issue">Report Issue</a-select-option>
              <a-select-option value="suggestion">Submit Suggestion</a-select-option>
              <a-select-option value="account">Account Related</a-select-option>
              <a-select-option value="other">Other</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Title" name="title">
            <a-input
                placeholder="Please enter a title (5-20 characters)"
                v-model:value="form.title"
                @paste="onPaste"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Email" name="email">
            <a-input
                placeholder="Please enter your email"
                v-model:value="form.email"
                @paste="onPaste"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-bottom: 10px">
        <a-col :span="20">
          Do you want to upload an image?
        </a-col>
        <a-col :span="4">
          <a-switch
              checked-children="Yes"
              un-checked-children="No"
              v-model:checked="checked"
          />
        </a-col>
      </a-row>
      <a-row v-if="checked" :gutter="16">
        <a-col :span="4">
          <a-form-item label="Image" name="image">
            <a-upload
                v-model:file-list="fileList"
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="SERVER + '/ebook/upload/avatar'"
                :before-upload="beforeUpload"
                @change="handleCoverChange"
            >
              <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
              <div v-else>
                <loading-outlined v-if="coverLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="20">
          <a-form-item label="Description" name="description">
            <a-textarea
                v-model:value="form.description"
                :rows="6"
                placeholder="Please enter description (20-500 characters)"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-else :gutter="16">
        <a-col :span="24">
          <a-form-item label="Description" name="description">
            <a-textarea
                v-model:value="form.description"
                :rows="6"
                placeholder="Please enter description (20-500 characters)"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div style="user-select: none">
      Note: You can also contact the administrator via <a :href="'mailto:dlwsdqdws@gmail.com'" target="_blank" style="color: red">dlwsdqdws@gmail.com</a>.
    </div>
    <div
        :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button style="margin-right: 8px" @click="onClose">Cancel</a-button>
      <a-popconfirm
          title="Please check your content before sending."
          ok-text="Ok"
          cancel-text="Back"
          @confirm="onSubmit"
      >
        <a-button type="primary">Submit</a-button>
      </a-popconfirm>
    </div>
  </a-drawer>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import axios from 'axios';
import store from "@/store";
import {message} from "ant-design-vue";
export default defineComponent({

  setup() {
    const form = reactive({
      type: '',
      title: '',
      email: '',
      image: "",
      description: '',
    });

    const formSaveReq = ref({
      name: "",
      fromId: 0,
      fromName: "",
      fromEmail: "",
      toId: 0,
      toName: "administrator",
      emergency: 0,
      content: "",
      link: "",
      image: ""
    })

    const rules = {
      type: [{ required: true, message: 'Please choose the type' }],
      title: [{ required: true, message: 'Please enter a title' }],
      email: [{ required: true, message: 'Please enter your email' }],
      image: [{ required: false, message: 'Upload an image' }],
      description: [{ required: true, message: 'Please enter url description' }],
    };

    const checked = ref<boolean>(false);
    const visible = ref<boolean>(false);
    const SERVER = process.env.VUE_APP_SERVER;
    const fileList = ref([]);
    const coverLoading = ref<boolean>(false);
    const imageUrl = ref<string>('');

    const beforeUpload = (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 < 256;
      if (!isLt2M) {
        message.error('Image must smaller than 256KB!');
      }
      return isJpgOrPng && isLt2M;
    };

    const getBase64 = (img: Blob, callback: (base64Url: string) => void) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result as string));
      reader.readAsDataURL(img);
    }

    const handleCoverChange = (info: any) => {
      if (info.file.status === 'upcoverLoading') {
        coverLoading.value = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (base64Url: string) => {
          imageUrl.value = base64Url;
          coverLoading.value = false;
        });

        form.image = SERVER + "/file/" + info.file.name;
      }
      if (info.file.status === 'error') {
        coverLoading.value = false;
        message.error('upload error');
      }
    };

    const showDrawer = () => {
      visible.value = true;
    };

    const onPaste = (event: { preventDefault: () => void; }) => {
      event.preventDefault();
    }

    const clearForm = () => {
      form.type = "";
      form.title = "";
      form.email = "";
      form.description = "";
      form.image = "";

      formSaveReq.value.name = "";
      formSaveReq.value.fromId = 0;
      formSaveReq.value.fromName = "";
      formSaveReq.value.fromEmail = "";
      formSaveReq.value.emergency = 0;
      formSaveReq.value.content = "";
      formSaveReq.value.link = "";
      formSaveReq.value.image = "";

      checked.value = false;
    }

    const onClose = () => {
      visible.value = false;
      clearForm();
    };

    const isValidEmail = (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    const onSubmit = () => {
      // check info
      if (!form.type) {
        message.error("Please choose a type");
        return;
      }

      if (form.title.length < 5 || form.title.length > 20) {
        message.error('Title must be between 5 and 20 characters long');
        return;
      }

      if (!isValidEmail(form.email)) {
        message.error('Invalid email');
        return;
      }

      if (form.description.length < 20 || form.description.length > 500) {
        message.error('Description must be between 20 and 500 characters long');
        return;
      }

      formSaveReq.value.name = form.type;
      formSaveReq.value.fromId =  store.state.user.id;
      formSaveReq.value.fromName = store.state.user.loginName;
      formSaveReq.value.fromEmail = form.email;
      formSaveReq.value.toId = 0;
      formSaveReq.value.toName = "administrator";
      formSaveReq.value.emergency = 0;
      formSaveReq.value.content = form.description;
      formSaveReq.value.link = form.title;
      formSaveReq.value.image = form.image;

      axios.post("/form/send-form", formSaveReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;

        if (data.success) {
          message.success("Submitted successfully, thank you for your message!")
        } else {
          message.error(data.message);
        }

        clearForm();
        visible.value = false;
      });
    }

    return {
      form,
      rules,
      visible,
      showDrawer,
      onClose,
      onSubmit,
      onPaste,

      SERVER,
      beforeUpload,
      fileList,
      coverLoading,
      imageUrl,
      handleCoverChange,

      checked,
    };
  },
});
</script>

<style scoped>
img {
  width: 50px;
  height: 50px;
}

.avatar-uploader{
  margin-top: 5px;
}
</style>
