<template>
  <div>
    <div id="revolvermap"></div>
  </div>
</template>

<script>
export default {
  name: 'RevolverMap',
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://rf.revolvermaps.com/0/0/1.js?i=5g30lvuidqx&s=220&m=0&v=false&r=false&b=000000&n=false&c=ff0000';
    script.async = true;
    document.getElementById('revolvermap').appendChild(script);
  },
};
</script>