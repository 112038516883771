<template>
  <div class="about-me" :class="{'min-height-610': user.id, 'min-height-650': !user.id}">
    <h1>Welcome to Lei's Blog</h1>
    <div class="profile">
      <img src="https://servelessproject-static-files.oss-cn-hongkong.aliyuncs.com/images/1652072217230.jpeg" alt="Profile Image" />
      <div class="info">
        <h2>{{ name }}</h2>
        <p>{{ occupation }}</p>
        <p><a :href="'mailto:' + email">{{ email }}</a></p>
      </div>
    </div>
    <div class="description">
      <h2>Introduction</h2>
      <p>{{ introduction }}</p>
    </div>
    <div class="interests">
      <h2>Interests</h2>
      <ul>
        <li v-for="interest in interests" :key="interest">{{ interest }}</li>
      </ul>
    </div>
    <button @click="handleClick" style="cursor: pointer;">Go to My Homepage</button>
  </div>
</template>

<script>
import store from "@/store";
import { computed } from 'vue';

export default {
  name: 'AboutMe',
  data() {
    return {
      name: 'Lei Lu',
      occupation: 'Software Engineer',
      email: 'dlwsdqdws@gmail.com',
      introduction: 'A programming enthusiast proficient in C++, Python, Java, and JavaScript with expertise in various front-end and back-end frameworks.',
      interests: ['Cooking', 'Hiking', 'Play Cards & Mahjong'],
    };
  },

  methods: {
    handleClick() {
      window.open('https://blog.dlwsdqdws.top');
    },
  },

  setup() {
    const user = computed(() => store.state.user);

    return {
      user,
    };
  },
};
</script>

<style scoped>
.about-me {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  user-select: none;
}

.min-height-610 {
  min-height: 610px;
}

.min-height-650 {
  min-height: 650px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.profile img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile .info h2 {
  font-size: 24px;
  margin-bottom: 5px;
}

.profile .info p {
  color: #888;
  margin-bottom: 5px;
}

.description {
  margin-bottom: 20px;
}

.interests h2 {
  margin-bottom: 10px;
}

.interests ul {
  list-style: none;
  padding: 0;
}

.interests li {
  margin-bottom: 5px;
}
</style>