import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from "../views/AboutView.vue"
import AdminUser from '../views/admin/admin-user.vue'
import AdminEbook from "../views/admin/admin-ebook.vue"
import AdminCategory from "../views/admin/admin-category.vue"
import AdminDoc from '../views/admin/admin-doc.vue'
import CustomerUser from "../views/customer/costomer-user.vue"
import CustomerNotice from "@/views/customer/customer-notice.vue";
import DocView from '../views/DocView.vue'
import SpiderTest from "../views/effects/spider.vue";
import ValueTest from "../views/effects/value.vue";
import TheTest from "../components/the-test.vue";

import store from "@/store";
import {Tool} from "@/util/tool";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/doc',
    name: 'DocView',
    component: DocView
  },
  {
    path: '/admin/user',
    name: 'AdminUser',
    component: AdminUser,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/admin/ebook',
    name: 'AdminEbook',
    component: AdminEbook,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/admin/category',
    name: 'AdminCategory',
    component: AdminCategory,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/admin/doc',
    name: 'AdminDoc',
    component: AdminDoc,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/customer/user',
    name: 'CustomerUser',
    component: CustomerUser,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/customer/notice',
    name: 'CustomerNotice',
    component: CustomerNotice,
    meta: {
      loginRequire: true
    }
  },
  {
    path: '/test/spider',
    name: 'SpiderTest',
    component: SpiderTest,
  },
  {
    path: '/test/value',
    name: 'ValueTest',
    component: ValueTest,
  },
  {
    path: '/test/test',
    name: 'TheTest',
    component: TheTest,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Route login interception
router.beforeEach((to, from, next) => {
  if (to.matched.some(function (item) {
    // console.log(item, "Route login interception? ", item.meta.loginRequire);
    return item.meta.loginRequire
  })) {
    const loginUser = store.state.user;
    if (Tool.isEmpty(loginUser)) {
      // console.log("Not login yet");
      next('/');
    } else if (to.path.startsWith('/admin/') && loginUser.loginName !== 'luleilulei') {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
