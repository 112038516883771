<template>
  <div>
    <p>From Name: {{ chatData.fromName }}</p>
    <p>To Name: {{ chatData.toName }}</p>
  </div>
</template>

<script>
export default {
  props: {
    chatData: {
      type: Object,
      required: true
    }
  }


}
</script>