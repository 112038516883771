import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "vertical-p" }
const _hoisted_3 = { class: "vertical-p" }
const _hoisted_4 = { class: "vertical-p" }
const _hoisted_5 = { class: "left-p" }
const _hoisted_6 = { class: "right-p" }
const _hoisted_7 = { class: "right-p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_the_calendar = _resolveComponent("the-calendar")!
  const _component_the_chat = _resolveComponent("the-chat")!
  const _component_portal = _resolveComponent("portal")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_a_layout_footer, {
          key: 0,
          style: {"text-align":"center"}
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("p", null, "Welcome " + _toDisplayString(_ctx.user.name), 513), [
              [_vShow, _ctx.user.id]
            ]),
            _createTextVNode(" Copyright © 2023 lulei 版權所有 | 赣ICP备2023002840号-1 ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isMobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_portal, { to: "body" }, {
            default: _withCtx(() => [
              (_ctx.showPopup)
                ? (_openBlock(), _createBlock(_component_the_calendar, {
                    key: 0,
                    class: "the-calendar"
                  }))
                : _createCommentVNode("", true),
              (_ctx.user.id && _ctx.showChat)
                ? (_openBlock(), _createBlock(_component_the_chat, {
                    key: 1,
                    class: "the-chat"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 6 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "left-panel",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePopup && _ctx.togglePopup(...args)))
                  }, [
                    (_ctx.user.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentDate), 1),
                          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.currentTime), 1),
                          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.lunarDate), 1)
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.currentDate), 1),
                          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.currentTime), 1),
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.lunarDate), 1)
                        ], 64))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 13 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_layout_footer, { class: "center-footer" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("p", null, "Welcome, " + _toDisplayString(_ctx.user.name), 513), [
                        [_vShow, _ctx.user.id]
                      ]),
                      _createTextVNode(" Copyright © 2023 lulei 版權所有 | 赣ICP备2023002840号-1 ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 3,
                style: {"display":"flex","align-items":"center","margin-left":"auto"}
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", null, [
                    _createElementVNode("div", {
                      style: {fontSize: '30px', color: '#08c', cursor: 'pointer'},
                      title: "Click to open chat box",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePopupChat && _ctx.togglePopupChat(...args)))
                    }, "💬")
                  ], 512), [
                    [_vShow, _ctx.user.id]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 2,
                style: {"display":"flex","align-items":"center","margin-left":"auto"}
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", null, [
                    _createElementVNode("div", {
                      style: {fontSize: '30px', color: '#08c', cursor: 'pointer'},
                      title: "Click to open chat box",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePopupChat && _ctx.togglePopupChat(...args)))
                    })
                  ], 512), [
                    [_vShow, _ctx.user.id]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}