<template>
  <a-layout>
    <the-header class="fixed-header"></the-header>
    <router-view />
    <the-footer></the-footer>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheHeader from '@/components/the-header.vue';
import TheFooter from '@/components/the-footer.vue';

export default defineComponent({
  name: 'app',
  components: {
    TheHeader,
    TheFooter
  },
  methods:{
    note(){
      (function () {
        window.onclick = function (event) {
          const words = ["富强", "民主", "文明", "和谐", "自由", "平等", "公正", "法治", "爱国", "敬业", "诚信", "友善"];

          let heart = document.createElement("b"); //创建b元素
          heart.onselectstart = function () { return false; };
          heart.ondragstart = function () { return false; };

          const a_idx = Math.floor(Math.random() * words.length);
          document.body.appendChild(heart).innerHTML = '♡️' + words[a_idx] + '♡️'; //将b元素添加到页面上
          heart.style.cssText = "position: fixed;left:-100%;"; //给p元素设置样式

          let f = 16, // 字体大小
              x = event.clientX - f / 2, // 横坐标
              y = event.clientY - f, // 纵坐标
              c = randomColor(), // 随机颜色
              a = 1, // 透明度
              s = 1.2; // 放大缩小

          const timer = setInterval(function () { //添加定时器
            if (a <= 0) {
              document.body.removeChild(heart);
              clearInterval(timer);
            } else {
              heart.style.cssText = "font-size:8px;cursor: default;position: fixed;color:" +
                  c + ";left:" + x + "px;top:" + y + "px;opacity:" + a + ";transform:scale(" +
                  s + ");";

              y--;
              a -= 0.016;
              s += 0.002;
            }
          }, 15)

        }
        // 随机颜色
        function randomColor() {
          return "rgb(" + (~~(Math.random() * 255)) + "," + (~~(Math.random() * 255)) + "," + (~~(Math
              .random() * 255)) + ")";

        }
      }());
    }
  },
  mounted() {
    this.note()
  }
});
</script>

<style>
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}
</style>
