<template>
  <template v-if="isMobile">
    <a-layout>
      <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }">
        <h3 v-if="level1.length === 0">This document doesn't exist!</h3>
        <a-col>
          <a-row :span="12">
            <a-tree
                v-if="level1.length > 0"
                :tree-data="level1"
                @select="onSelect"
                :replaceFields="{title: 'name', key: 'id', value: 'id'}"
                :defaultExpandAll="true"
                :defaultSelectedKeys="defaultSelectedKeys"
            >
            </a-tree>
          </a-row>
          <a-row :span="12">
            <div v-if="level1.length !== 0">
              <h2>{{doc.name}}</h2>
              <div>
                <span>Reading Nums: {{doc.viewCount}}</span> &nbsp; &nbsp;
                <span>Voting Nums: {{doc.voteCount}}</span>
              </div>
              <a-divider style="height: 2px; background-color: #9999cc"/>
            </div>
            <div class="wangeditor" :innerHTML="html"></div>
            <div class="vote-div" v-if="level1.length !== 0" style="margin-left: 32%">
              <a-button type="primary" shape="round" :size="'small'" @click="vote" >
                <template #icon><LikeOutlined /> &nbsp;Like：{{doc.voteCount}} </template>
              </a-button>
            </div>
          </a-row>
        </a-col>
      </a-layout-content>
    </a-layout>
  </template>

  <template v-if="!isMobile">
    <a-layout style="min-height: 650px">
      <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }">
        <h3 v-if="level1.length === 0">This document doesn't exist!</h3>
        <a-row>
          <a-col :span="6">
            <div style="font-size: 20px; font-weight: 800; font-style:italic;">{{ebookName}}</div>
            <a-tree
                v-if="level1.length > 0"
                :tree-data="level1"
                @select="onSelect"
                :replaceFields="{title: 'name', key: 'id', value: 'id'}"
                :defaultExpandAll="true"
                :defaultSelectedKeys="defaultSelectedKeys"
            >
            </a-tree>
          </a-col>
          <a-col :span="18">
            <div v-if="level1.length !== 0">
              <h2>{{doc.name}}</h2>
              <div>
                <span>Reading Nums: {{doc.viewCount}}</span> &nbsp; &nbsp;
                <span>Voting Nums: {{doc.voteCount}}</span>
              </div>
              <a-divider style="height: 2px; background-color: #9999cc"/>
            </div>
            <div class="wangeditor" :innerHTML="html"></div>
            <div class="vote-div" v-if="level1.length !== 0">
              <a-button type="primary" shape="round" :size="'large'" @click="vote">
                <template #icon><LikeOutlined /> &nbsp;Like：{{doc.voteCount}} </template>
              </a-button>
            </div>

<!--            comments-->
            <a-divider style="height: 2px; background-color: #9999cc"/>
            <h3><CommentOutlined /> {{comment_nums}} Comments</h3>
            <div style="letter-spacing: 1px">
              <div v-if="letter" class="head">
                <a-avatar
                    size="large"
                    :style="{ backgroundColor: getRandomColor(user.id), verticalAlign: 'middle' }"
                    style="position: absolute; top: 25px; left: 13px; user-select: none; cursor: pointer;"
                >
                  {{ letter }}
                </a-avatar>
                <textarea
                    placeholder="Type comment here ... (Markdown is supported, no more than 500 characters)"
                    ref="input"
                    v-model="firstComments"
                    @keyup.enter="submitPost"
                    @keyup="handleKeyUp"
                    class="comment-input"
                ></textarea>
                <a-button @click="submitPost">Post</a-button>
              </div>
              <a-empty v-if="comment.length === 0" style="margin-top: 10px" description="No comment yet"/>
              <div v-else class="content">
                <div class="first" v-for="(item, index) in comment" :key="index" style="display: flex;">
                  <a-avatar
                      size="large"
                      shape="circle"
                      :style="{ backgroundColor: getRandomColor(item.userId), verticalAlign: 'middle' }"
                      style="flex: none; text-align: center; user-select: none; cursor: pointer; margin: 10px 10px 0 2px;"
                  >
                    {{ item.username.charAt(0).toUpperCase() }}
                  </a-avatar>
                  <div class="first-content"  style="flex: 1;">
                    <div class="username-container">
                      <h3 class="first-username" style="color: #504f4f; margin-top: 2px; margin-bottom: 1px; user-select: none;">{{ item.username }}</h3>
                      <div class="icons-container">
                        <div class="icon" v-if="user?.loginName === 'luleilulei' || user?.loginName === item.username" @click="comment_delete_parent(item)" style="user-select: none; cursor: pointer;">
                          <DeleteTwoTone style="margin-right: 4px;" />
                          Delete
                        </div>
                        <div class="icon" @click="comment_reply(item)" style="user-select: none; cursor: pointer;" v-if="user?.loginName?.length">
                          <MessageTwoTone style="margin-right: 4px;" />
                          Reply
                        </div>
                        <div class="icon" @click="comment_like(item)" style="user-select: none; cursor: pointer;">
                          <SmileTwoTone two-tone-color="#52c41a" style="margin-right: 4px;" />
                          <span>{{ item.likeCount }}</span>
                        </div>
                        <div class="icon" @click="comment_dislike(item)" style="user-select: none; cursor: pointer;">
                          <FrownTwoTone two-tone-color="#eb2f96" style="margin-right: 4px;" />
                          <span>{{ item.dislikeCount }}</span>
                        </div>
                        <div class="icon">
                          <FireTwoTone two-tone-color="#F40707" v-if="item.topCount" @click="comment_top(item)" />
                          <FireTwoTone  v-else @click="comment_top(item)" />
                        </div>
                      </div>
                    </div>
                    <div class="details-container">
                      <span class="first-time">{{ formatDate(item.date) }}</span>
                      <span class="address-details">{{ item.address }}</span>
                    </div>
                    <div
                        v-html="parseMarkdown(item.content)"
                        class="first-comment"
                        style="
                        font-size: 16px;
                        margin-top: 5px;
                        max-width: 90%;
                        word-wrap: break-word;
                        word-break: break-all;"
                    ></div>
                    <div class="reply-comment" v-if="item.reply_display">
                      <div class="reply-input">
                      <textarea
                          type="text"
                          placeholder="  Type reply here ... ... (Markdown is supported, no more than 500 characters)"
                          v-model="item.reply_content"
                          @keyup="handleKeyUpFirst(item)"
                          class="reply-textarea"
                      />
                        <button @click="reply_submit(item)" class="reply-button">Reply</button>
                      </div>
                      <!--    comment first reply verification code-->
                      <a-modal v-model:visible="imageCodeFirstReplyModalVisible" :title="null" :footer="null" :closable="false"
                               style="top: 50px; width: 400px">
                        <p style="text-align: center; font-weight: bold; font-size: 18px">
                          Reply cannot be modified once being posted. Please enter verification code.
                        </p>
                        <p>
                          <a-input v-model:value="imageCodeFirstReply" :maxlength="10" placeholder="Verification Code">
                            <template #suffix>
                              <img v-show="!!imageCodeSrc" :src="imageCodeSrc" alt="kaptcha" v-on:click="loadImageCode()"/>
                            </template>
                          </a-input>
                        </p>
                        <a-button type="primary" danger block @click="commentPostReply(item)">Reply</a-button>
                      </a-modal>
                    </div>

                    <div class="second">
                      <ul style="list-style-type: none;">
                        <li v-for="(sons, index) in item.sons" :key="index" class="comment-item">
                          <div class="avatar-container">
                            <a-avatar
                                size="large"
                                shape="circle"
                                :style="{ backgroundColor: getRandomColor(sons.userId), verticalAlign: 'middle' }"
                            >
                              {{ sons.username.charAt(0).toUpperCase() }}
                            </a-avatar>
                          </div>
                          <div class="content-container" style="flex: 1;">
                            <div class="username-container">
                              <h3 class="first-username" style="color: #504f4f; margin-top: 2px; margin-bottom: 1px; user-select: none;">
                                {{ sons.username }}
                                <span style="font-size: 12px; font-weight: 300;">replied</span>
                                {{ sons.parentName }}
                              </h3>
                              <div class="icons-container">
                                <div class="icon" @click="comment_delete_son(sons)" v-if="user?.loginName === 'luleilulei' || user?.loginName === item.username" style="user-select: none; cursor: pointer;">
                                  <DeleteTwoTone style="margin-right: 4px;" />
                                  Delete
                                </div>
                                <div class="icon" @click="comment_reply(sons)" style="user-select: none; cursor: pointer;"  v-if="user?.loginName?.length">
                                  <MessageTwoTone style="margin-right: 4px;" />
                                  Reply
                                </div>
                                <div class="icon" @click="comment_like(sons)" style="user-select: none; cursor: pointer;">
                                  <SmileTwoTone two-tone-color="#52c41a" style="margin-right: 4px;" />
                                  <span>{{ sons.likeCount }}</span>
                                </div>
                                <div class="icon" @click="comment_dislike(sons)" style="user-select: none; cursor: pointer;">
                                  <FrownTwoTone two-tone-color="#eb2f96" style="margin-right: 4px;" />
                                  <span>{{ sons.dislikeCount }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="details-container">
                              <span class="first-time">{{ formatDate(sons.date) }}</span>
                              <span class="address-details">{{ sons.address }}</span>
                            </div>
                            <div
                                v-html="parseMarkdown(sons.content)"
                                class="first-comment"
                                style="
                                font-size: 16px;
                                margin-top: 5px;
                                max-width: 90%;
                                word-wrap: break-word;
                                word-break: break-all;"
                            ></div>

                            <div class="reply-comment" v-if="sons.reply_display">
                              <div class="reply-input">
                                <textarea
                                    type="text"
                                    placeholder="  Type reply here ... ... (Markdown is supported, no more than 500 characters)"
                                    v-model="sons.reply_content"
                                    @keyup="handleKeyUpFirst(sons)"
                                    class="reply-textarea"
                                />
                                <button @click="reply_submit_second(sons)" class="reply-button-second">Reply</button>
                              </div>

                              <!--    comment second reply verification code-->
                              <a-modal v-model:visible="imageCodeSecondReplyModalVisible" :title="null" :footer="null" :closable="false"
                                       style="top: 50px; width: 400px">
                                <p style="text-align: center; font-weight: bold; font-size: 18px">
                                  Reply cannot be modified once being posted. Please enter verification code.
                                </p>
                                <p>
                                  <a-input v-model:value="imageCodeSecondReply" :maxlength="10" placeholder="Verification Code">
                                    <template #suffix>
                                      <img v-show="!!imageCodeSrc" :src="imageCodeSrc" alt="kaptcha" v-on:click="loadImageCode()"/>
                                    </template>
                                  </a-input>
                                </p>
                                <a-button type="primary" danger block @click="commentSecondPostReply(item, sons)">Reply</a-button>
                              </a-modal>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>

<!--    comment post verification code-->
    <a-modal v-model:visible="imageCodeModalVisible" :title="null" :footer="null" :closable="false"
             style="top: 50px; width: 400px">
      <p style="text-align: center; font-weight: bold; font-size: 18px">
        Comment cannot be modified once being posted. Please enter verification code.
      </p>
      <p>
        <a-input v-model:value="imageCode" :maxlength="10" placeholder="Verification Code">
          <template #suffix>
            <img v-show="!!imageCodeSrc" :src="imageCodeSrc" alt="kaptcha" v-on:click="loadImageCode()"/>
          </template>
        </a-input>
      </p>
      <a-button type="primary" danger block @click="commentPost">Post</a-button>
    </a-modal>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import {message, Modal} from 'ant-design-vue';
import {Tool} from "@/util/tool";
import {useRoute} from "vue-router";
import store from "@/store";
import MarkdownIt from 'markdown-it';

import hljs from 'highlight.js';
// import 'highlight.js/styles/default.css';
import 'highlight.js/styles/arduino-light.css';

import cpp from 'highlight.js/lib/languages/cpp';
import python from 'highlight.js/lib/languages/python';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import go from 'highlight.js/lib/languages/go';
import ruby from 'highlight.js/lib/languages/ruby';
import swift from 'highlight.js/lib/languages/swift';
import php from 'highlight.js/lib/languages/php';
import csharp from 'highlight.js/lib/languages/csharp';
import rust from 'highlight.js/lib/languages/rust';
import kotlin from 'highlight.js/lib/languages/kotlin';
import html from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import json from 'highlight.js/lib/languages/json';

hljs.registerLanguage('cpp', cpp);
hljs.registerLanguage('python', python);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('go', go);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('swift', swift);
hljs.registerLanguage('php', php);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('rust', rust);
hljs.registerLanguage('kotlin', kotlin);
hljs.registerLanguage('html', html);
hljs.registerLanguage('css', css);
hljs.registerLanguage('json', json);

export default defineComponent({
  name: 'DocView',
  setup() {
    let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    const route = useRoute();
    const docs = ref();
    const html = ref();
    const ebookName = ref();

    const defaultSelectedKeys = ref();
    defaultSelectedKeys.value = [];

    const doc = ref();
    doc.value = {};

    const user = store.state.user || "";

    const level1 = ref();
    level1.value = [];

    const getRandomColor = (id: any) => {
      const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff0000', '#0000ff'];

      const storedColor = localStorage.getItem(id);
      if (storedColor) {
        return storedColor;
      } else {
        const randomIndex = Math.floor(Math.random() * colorList.length);
        const randomColor = colorList[randomIndex];
        localStorage.setItem(id, randomColor);
        return randomColor;
      }
    }

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const now = new Date();
      const diff = Math.abs(now.getTime() - date.getTime());
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (diff < 1000 * 60) {
        return 'Just now';
      } else if (diff < 1000 * 60 * 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24 * 30) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
      } else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    }

    const parseMarkdown = (text: any) => {
      const markdownIt = new MarkdownIt();
      return markdownIt.render(text);
    }

    const letter = ref();
    const firstComments = ref();
    const maxCharacterCount = 500;

    const handleKeyUp = () => {
      if (firstComments.value?.length > maxCharacterCount) {
        firstComments.value = firstComments.value.slice(0, maxCharacterCount);
      }
    }

    const comment = ref();
    comment.value = [];

    const escapeHtml = (content: string) => {
      const element = document.createElement('div');
      element.innerText = content;
      return element.innerHTML;
    };

    const handleQueryContent = (id: number) => {
      axios.get("/doc/find-content/" + id).then((response) => {
        const data = response.data;
        if (data.success) {
          const content = data.content;
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = content;
          const codeElements = tempDiv.querySelectorAll('code');

          codeElements.forEach((codeElement) => {
            hljs.highlightElement(codeElement);
          });

          const highlightedContent = tempDiv.innerHTML;
          const escapedContent = escapeHtml(highlightedContent);
          html.value = highlightedContent;
        } else {
          message.error(data.message);
        }
      });
    };

    const handleQueryEbookName = () => {
      axios.get("/ebook/get-name/" + route.query.ebookId).then((response) => {
        const data = response.data;
        if (data.success){
          ebookName.value = data.content;
        } else {
          message.error(data.message);
        }
      });
    }

    const handleQuery = () => {
      axios.get("/doc/all/" + route.query.ebookId).then((response) => {
        const data = response.data;
        if (data.success) {
          docs.value = data.content;

          level1.value = [];
          level1.value = Tool.array2Tree(docs.value, 0);

          // show the first doc by default
          if (Tool.isNotEmpty(level1) && Tool.isNotEmpty(level1.value) && level1.value.length > 0 && level1.value[0].id) {
            if (level1.value[0].id) {
              defaultSelectedKeys.value = [level1.value[0].id];
              handleQueryContent(level1.value[0].id);
              doc.value = level1.value[0];

              // get comments of default doc
              handleQueryComment(doc.value.id);
            }
          }
        } else {
          message.error(data.message);
        }
      });
    };

    const comment_nums = ref(0);

    const handleQueryComment = (id: any) => {
      axios.get("/comment/all-doc/" + id).then((response) => {
        const data = response.data;
        if (data.success) {
          let comments = data.content || [];
          comment_nums.value = comments.length;
          comments.forEach((c: any) => {
            c.sons = [];
            c.reply_display = false;
            c.reply_content = "";
          });

          const commentMap: { [key: string]: any } = {};
          comments.forEach((c: any) => {
            commentMap[c.id] = c;
          });

          comments.sort((a: any, b: any) => a.data - b.data);

          comments = comments.filter((c: any) => {
            if (c.parentId != 0) {
              const parent = commentMap[c.parentId];
              if (parent) {
                parent.sons.push(c);
              }
              return false;
            }
            return true;
          });

          comment.value = comments;
        } else {
          message.error(data.message);
        }
      });
    }

    const onSelect = (selectedKeys: any, info: any) => {
      // console.log('selected', selectedKeys, info);
      if (Tool.isNotEmpty(selectedKeys)) {
        doc.value = info.selectedNodes[0].props;
        handleQueryContent(selectedKeys[0]);

        // get comments of selected doc
        handleQueryComment(doc.value.id);
      }
    };

    const vote = () => {
      axios.get('/doc/vote/' + doc.value.id).then((response) => {
        const data = response.data;
        if (data.success) {
          doc.value.voteCount++;
        } else {
          message.error(data.message);
        }
      });
    };

    const commentSaveReq = ref({
      ebookId: 0,
      docId: 0,
      parentId: 0,
      replyId: 0,
      parentName: "",
      userId: 0,
      username: "",
      content: "",
      imageCodeToken: "",
      imageCode: ""
    });

    const formSaveReq = ref({
      name: "",
      fromId: 0,
      fromName: "",
      fromEmail: "",
      toId: 0,
      toName: "",
      emergency: 0,
      contentId: 0,
      content: "",
      link: "",
      category1Id: 0,
      category2Id: 0
    });

    const clearCommentSaveReq = () => {
      commentSaveReq.value.ebookId = 0;
      commentSaveReq.value.docId = 0;
      commentSaveReq.value.parentId = 0;
      commentSaveReq.value.replyId = 0;
      commentSaveReq.value.parentName = "";
      commentSaveReq.value.userId = 0;
      commentSaveReq.value.username = "";
      commentSaveReq.value.content = "";
      commentSaveReq.value.imageCode = "";
      commentSaveReq.value.imageCodeToken = "";
      imageCode.value = "";
    }

    const clearFormSaveReq = () => {
      formSaveReq.value.name = "";
      formSaveReq.value.fromId = 0;
      formSaveReq.value.fromName = "";
      formSaveReq.value.fromEmail = "";
      formSaveReq.value.toId = 0;
      formSaveReq.value.toName = "";
      formSaveReq.value.emergency = 0;
      formSaveReq.value.contentId = 0;
      formSaveReq.value.content = "";
      formSaveReq.value.link = "";
      formSaveReq.value.category1Id = 0;
      formSaveReq.value.category2Id = 0;
    }

    const imageCodeModalVisible = ref(false);
    const imageCodeToken = ref();
    const imageCodeSrc = ref();
    const imageCode = ref();

    const loadImageCode = () => {
      imageCodeToken.value = Tool.uuid(8);
      imageCodeSrc.value = process.env.VUE_APP_SERVER + '/kaptcha/image-code/' + imageCodeToken.value;
    }

    const commentPost = () => {
      if (imageCode.value == null || imageCode.value.length === 0) {
        message.error("Verification code cannot be empty!");
        loadImageCode();
        return ;
      }

      commentSaveReq.value.ebookId = doc.value.ebookId;
      commentSaveReq.value.docId = doc.value.id;
      commentSaveReq.value.parentId = 0;
      commentSaveReq.value.parentName = "";
      commentSaveReq.value.userId = user.id;
      commentSaveReq.value.username = user.loginName;
      commentSaveReq.value.imageCodeToken = imageCodeToken.value;
      commentSaveReq.value.imageCode = imageCode.value;
      commentSaveReq.value.content = firstComments.value;

      axios.post("/comment/save-comment", commentSaveReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success('Post successfully');
          firstComments.value = "";
          handleQueryComment(doc.value.id);

          imageCodeModalVisible.value = false;

          // send form request
          formSaveReq.value.name = "replied";
          formSaveReq.value.emergency = 2;
          formSaveReq.value.toId = 0;
          formSaveReq.value.toName = "";
          formSaveReq.value.fromId = user.id;
          formSaveReq.value.fromName = user.loginName;
          formSaveReq.value.content = Tool.truncateString(commentSaveReq.value.content, 20);
          formSaveReq.value.category1Id = doc.value.ebookId;
          formSaveReq.value.category2Id = doc.value.id;
          formSaveReq.value.link = ebookName.value + '/' + doc.value.name;

          clearCommentSaveReq();

          if (formSaveReq.value.fromName === "luleilulei") {
            clearFormSaveReq();
          } else {
            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          }

        } else {
          message.error(data.message);
          clearCommentSaveReq();
          imageCodeModalVisible.value = false;
        }
      }).then((response) => {
        clearFormSaveReq();
      });
    }

    const submitPost = () => {
      if (firstComments.value == null || firstComments.value.length === 0) {
        message.error("Comment cannot be empty!");
        return ;
      }

      loadImageCode();
      imageCodeModalVisible.value = true;
    }

    const comment_like = (item: any) => {
      axios.get("/comment/like/" + item.id).then((response) => {
        const data = response.data;
        if (data.success) {
          handleQueryComment(item.docId);


          // send form request
          formSaveReq.value.name = "liked";
          formSaveReq.value.emergency = 1;
          formSaveReq.value.toId = item.userId;
          formSaveReq.value.toName = item.username;
          formSaveReq.value.fromId = store.state.user.id;
          formSaveReq.value.fromName = store.state.user.loginName;
          formSaveReq.value.fromEmail = "";
          formSaveReq.value.contentId = item.id;
          formSaveReq.value.content = Tool.truncateString(item.content, 20);
          formSaveReq.value.category1Id = doc.value.ebookId;
          formSaveReq.value.category2Id = doc.value.id;
          formSaveReq.value.link = ebookName.value + '/' + doc.value.name;

          if (formSaveReq.value.fromId !== formSaveReq.value.toId) {
            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          } else {
            clearFormSaveReq();
          }
        } else {
          message.error(data.message);
        }
      }).then((response) => {
        clearFormSaveReq();
      });
    }

    const comment_dislike = (item: any) => {
      axios.get("/comment/dislike/" + item.id).then((response) => {
        const data = response.data;
        if (data.success) {
          handleQueryComment(item.docId);
        } else {
          message.error(data.message);
        }
      });
    }

    // reminder when top one comment more than 10 times in 24h
    const MAX_CLICK_COUNT = 10;
    const CLICK_RECORD_KEY = 'clickRecord';
    const CLICK_INTERVAL = 24 * 60 * 60 * 1000;

    const comment_top = (item: any) => {
      if (!("loginName" in user) || user?.loginName !== "luleilulei"){
        return ;
      }

      const clickRecord = localStorage.getItem(CLICK_RECORD_KEY);
      const clickTimes: number[] = clickRecord ? JSON.parse(clickRecord) : [];
      const currentTime = Date.now();
      const recentClicks = clickTimes.filter(time => currentTime - time <= CLICK_INTERVAL);

      if (recentClicks.length >= MAX_CLICK_COUNT) {
        message.warning(`You have clicked ${recentClicks.length} times in the past 24 hours.`);
      }

      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to top this comment?',
        onOk() {
          axios.get("/comment/top/" + item.id).then((response) => {
            const data = response.data;
            if (data.success) {
              message.success('You topped comment: ' + item.id);
              handleQueryComment(item.docId);

              // send form request
              formSaveReq.value.name = "topped";
              formSaveReq.value.emergency = 1;
              formSaveReq.value.toId = item.userId;
              formSaveReq.value.toName = item.username;
              formSaveReq.value.fromId = 0;
              formSaveReq.value.fromName = "administrator";
              formSaveReq.value.fromEmail = "dlwsdqdws@gmail.com";
              formSaveReq.value.contentId = item.id;
              formSaveReq.value.content = Tool.truncateString(item.content, 20);
              formSaveReq.value.category1Id = doc.value.ebookId;
              formSaveReq.value.category2Id = doc.value.id;
              formSaveReq.value.link = ebookName.value + '/' + doc.value.name;

              if (formSaveReq.value.toName === "luleilulei" || formSaveReq.value.toName === "dlwsdqdws") {
                clearFormSaveReq();
              } else {
                return axios.post("/form/send-form", formSaveReq.value, {
                  headers: {
                    'token': store.state.user.token,
                    'id': store.state.user.id
                  }
                });
              }
            } else {
              message.error(data.message);
            }
          }).then((response) => {
            clearFormSaveReq();
          });

          clickTimes.push(currentTime);
          localStorage.setItem(CLICK_RECORD_KEY, JSON.stringify(clickTimes));
        }
      });
    }

    const commentDeleteReq = ref({
      id: "",
      ebookId: "",
      docId: "",
      sons: []
    });

    const comment_delete_parent = (item: any) => {
      commentDeleteReq.value.id = item.id;
      commentDeleteReq.value.docId = item.docId;
      commentDeleteReq.value.ebookId = item.ebookId;

      if (item.sons && item.sons.length > 0) {
        commentDeleteReq.value.sons = item.sons.map((son: any) => son.id);
      }

      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to delete this comment? This action is irreversible!',
        onOk() {
          axios.post("/comment/delete-tree", commentDeleteReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            }).then((response) => {
              const data = response.data;
              if (data.success) {
                message.success('Delete successfully');
                handleQueryComment(item.docId);
              } else {
                message.error(data.message);
              }

              commentDeleteReq.value.id = "";
              commentDeleteReq.value.docId = "";
              commentDeleteReq.value.ebookId = "";
              commentDeleteReq.value.sons = [];
          });
        }
      });
    }

    const comment_delete_son = (item: any) => {
      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to delete this comment? This action is irreversible!',
        onOk() {
          axios.get("/comment/delete-id/" + item.id, {
            headers: {
              'token': store.state.user.token,
              'id': store.state.user.id
              }
            }).then((response) => {
              const data = response.data;
              if (data.success) {
                message.success('Delete successfully');
                handleQueryComment(item.docId);
              } else {
                message.error(data.message);
              }
          });
        }
      });
    }

    const handleKeyUpFirst = (item: any) => {
      if (item.reply_content?.length > 500) {
        item.reply_content = item.reply_content.slice(0, 500);
      }
    };

    const imageCodeFirstReplyModalVisible = ref(false);
    const imageCodeFirstReply = ref();

    const comment_reply = (item: any) => {
      item.reply_content = "";
      item.reply_display = !item.reply_display;

      // can only open one reply window everytime
      comment.value.forEach((c: any) => {
        if (c !== item) {
          c.reply_content = "";
          c.reply_display = false;

          // Check if c.sons is not an empty array
          if (c.sons && c.sons.length > 0) {
            c.sons.forEach((son: any) => {
              if (son !== item) {
                son.reply_content = "";
                son.reply_display = false;
              }
            });
          }
        } else {
          c.sons.forEach((son: any) => {
            son.reply_content = "";
            son.reply_display = false;
          });
        }
      });
    }

    const reply_submit = (item: any) => {
      if (item.reply_content == null || item.reply_content.length === 0) {
        message.error("Comment cannot be empty!");
        return;
      }

      loadImageCode();
      imageCodeFirstReplyModalVisible.value = true;
    }

    const commentPostReply = (item: any) => {
      if (imageCodeFirstReply.value == null || imageCodeFirstReply.value.length === 0) {
        message.error("Verification code cannot be empty!");
        loadImageCode();
        return ;
      }

      commentSaveReq.value.ebookId = item.ebookId;
      commentSaveReq.value.docId = item.docId;
      commentSaveReq.value.parentId = item.id;
      commentSaveReq.value.replyId = item.id;
      commentSaveReq.value.parentName = item.username;
      commentSaveReq.value.userId = user.id;
      commentSaveReq.value.username = user.loginName;
      commentSaveReq.value.imageCodeToken = imageCodeToken.value;
      commentSaveReq.value.imageCode = imageCodeFirstReply.value;
      commentSaveReq.value.content = item.reply_content;

      axios.post("/comment/save-comment", commentSaveReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success('Post successfully');
          item.reply_content = "";
          item.reply_display = false;
          handleQueryComment(doc.value.id);

          imageCodeFirstReply.value = "";
          imageCodeFirstReplyModalVisible.value = false;

          // send form request
          formSaveReq.value.name = "replied";
          formSaveReq.value.emergency = 2;
          formSaveReq.value.toId = item.userId;
          formSaveReq.value.toName = item.username;
          formSaveReq.value.fromId = user.id;
          formSaveReq.value.fromName = user.loginName;
          formSaveReq.value.content = Tool.truncateString(item.content, 20);
          formSaveReq.value.category1Id = doc.value.ebookId;
          formSaveReq.value.category2Id = doc.value.id;
          formSaveReq.value.link = ebookName.value + '/' + doc.value.name;
          clearCommentSaveReq();

          if (formSaveReq.value.fromId !== formSaveReq.value.toId) {
            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          } else {
            clearFormSaveReq();
          }
        } else {
          message.error(data.message);
          clearCommentSaveReq();
          imageCodeFirstReply.value = "";
          imageCodeFirstReplyModalVisible.value = false;
        }
      }).then((response) => {
        clearFormSaveReq();
      });
    }

    const imageCodeSecondReplyModalVisible = ref(false);
    const imageCodeSecondReply = ref();

    const reply_submit_second = (sons: any) => {
      if (sons.reply_content == null || sons.reply_content.length === 0) {
        message.error("Comment cannot be empty!");
        return;
      }

      loadImageCode();
      imageCodeSecondReplyModalVisible.value = true;
    }

    const commentSecondPostReply = (item: any, sons: any) => {
      if (imageCodeSecondReply.value == null || imageCodeSecondReply.value.length === 0) {
        message.error("Verification code cannot be empty!");
        loadImageCode();
        return ;
      }

      commentSaveReq.value.ebookId = item.ebookId;
      commentSaveReq.value.docId = item.docId;
      commentSaveReq.value.parentId = item.id;
      commentSaveReq.value.replyId = sons.id;
      commentSaveReq.value.parentName = sons.username;
      commentSaveReq.value.userId = user.id;
      commentSaveReq.value.username = user.loginName;
      commentSaveReq.value.imageCodeToken = imageCodeToken.value;
      commentSaveReq.value.imageCode = imageCodeSecondReply.value;
      commentSaveReq.value.content = sons.reply_content;

      axios.post("/comment/save-comment", commentSaveReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success('Post successfully');
          sons.reply_content = "";
          sons.reply_display = false;
          handleQueryComment(doc.value.id);

          imageCodeSecondReply.value = "";
          imageCodeSecondReplyModalVisible.value = false;

          // send form request
          formSaveReq.value.name = "replied";
          formSaveReq.value.emergency = 2;
          formSaveReq.value.toId = sons.userId;
          formSaveReq.value.toName = sons.username;
          formSaveReq.value.fromId = user.id;
          formSaveReq.value.fromName = user.loginName;
          formSaveReq.value.content = Tool.truncateString(sons.content, 20);
          formSaveReq.value.category1Id = doc.value.ebookId;
          formSaveReq.value.category2Id = doc.value.id;
          formSaveReq.value.link = ebookName.value + '/' + doc.value.name;
          clearCommentSaveReq();

          if (formSaveReq.value.fromId !== formSaveReq.value.toId) {
            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          } else {
            clearFormSaveReq();
          }
        } else {
          message.error(data.message);
          clearCommentSaveReq();
          imageCodeSecondReply.value = "";
          imageCodeSecondReplyModalVisible.value = false;
        }
      }).then((response) => {
        clearFormSaveReq();
      });
    }

    onMounted(() => {
      if (store.state.user) {
        letter.value = store.state.user.loginName?.charAt(0).toUpperCase();
      } else {
        letter.value = "";
      }

      handleQueryEbookName();
      handleQuery();
    });

    return {
      level1,

      html,
      onSelect,
      defaultSelectedKeys,

      doc,
      ebookName,

      user,

      vote,

      isMobile,

      getRandomColor,
      letter,
      submitPost,
      firstComments,
      handleKeyUp,

      comment,
      formatDate,
      parseMarkdown,

      comment_like,
      comment_dislike,
      comment_top,
      comment_delete_parent,
      comment_delete_son,
      comment_reply,

      comment_nums,

      imageCodeModalVisible,
      imageCodeToken,
      imageCodeSrc,
      imageCode,
      loadImageCode,
      commentPost,

      reply_submit,
      handleKeyUpFirst,
      imageCodeFirstReplyModalVisible,
      imageCodeFirstReply,
      commentPostReply,

      reply_submit_second,
      imageCodeSecondReplyModalVisible,
      imageCodeSecondReply,
      commentSecondPostReply,
    }
  }
});
</script>

<style>
/* http://www.wangeditor.com/doc/pages/02-%E5%86%85%E5%AE%B9%E5%A4%84%E7%90%86/03-%E8%8E%B7%E5%8F%96html.html */
/* table */
.wangeditor {
  max-width: 100%;
}
.wangeditor table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.wangeditor table td,
.wangeditor table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
.wangeditor table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote */
.wangeditor blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code */
.wangeditor code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.wangeditor pre code {
  display: block;
}

/* ul ol */
.wangeditor ul, ol {
  margin: 10px 0 10px 20px;
}

/* p-label conflict with antdv */
.wangeditor blockquote p {
  font-family:"YouYuan";
  margin: 20px 10px !important;
  font-size: 16px !important;
  font-weight:600;
}

/*image autofill*/
.wangeditor img {
  max-width: 100%;
  height: auto;
}

/*video autofill*/
.wangeditor iframe {
  width: 100%;
  height: 400px;
}

.vote-div {
  padding: 15px;
  text-align: center;
}

.head {
  background-color: rgb(248, 248, 248);
  position: relative;
  height: 95px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.comment-input {
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
  height: auto;
  border-radius: 5px;
  outline: none;
  width: 85%;
  font-size: 15px;
  padding: 10px;
  border: 2px solid #f8f8f8;
  resize: none;
}

.head button {
  position: absolute;
  top: 50%;
  left: calc(85% + 70px);
  transform: translateY(-50%);
  height: 45px;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background-color: rgb(118, 211, 248);
  cursor: pointer;
  letter-spacing: 2px;
}

.username-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.icons-container {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
}

.second {
  background-color: #f3f3f3;
  margin-top: 10px;
}

.second li {
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.comment-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.avatar-container {
  flex: none;
  margin-left: -30px;
  margin-right: 10px;
  margin-top: 2px;
}

.details-container {
  display: flex;
  align-items: center;
}

.first-time,
.address-details {
  color: #767575;
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 12px;
  user-select: none;
  white-space: nowrap;
}

.address-details {
  margin-left: 10px;
}

.reply-comment {
  margin-top: 10px;
}

.reply-input {
  display: flex;
  align-items: flex-start;
}

.reply-textarea {
  width: 80%;
  height: 60px;
  resize: vertical;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.reply-button {
  margin-top: 28px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reply-button-second {
  margin-top: 25px;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reply-button:hover {
  background-color: #0056b3;
}

</style>
