<template>
  <a-layout>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '610px' }"
    >
      <a-table
          :columns="columns"
          :dataSource="users"
          :row-key="record => record.id"
          :pagination="false"
          style="margin-bottom: 20px"
      >
        <template v-slot:action="{ text, record }">
          <a-space size="small">
            <a-button type="primary" @click="resetPassword(record)">
              Reset Password
            </a-button>
            <a-button type="primary" @click="resetNickname(record)">
              Reset Nickname
            </a-button>
            <a-button type="primary" @click="resetEmail(record)">
              Reset Email
            </a-button>
            <a-popconfirm
                title="Are you sure delete this account?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="closeAccount(record)"
            >
              <a-button type="primary" danger>
                Delete
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>

      <a-divider style="border-color: #7cb305" dashed />
    </a-layout-content>
  </a-layout>

  <a-modal
      title="Reset Nickname"
      v-model:visible="resetNameModalVisible"
      :confirm-loading="resetNameModalLoading"
      @ok="handleResetNickname"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Nickname">
        <a-input v-model:value="user.name" />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
      title="Reset Password"
      v-model:visible="resetPasswordModalVisible"
      :confirm-loading="resetPasswordModalLoading"
      @ok="handleResetPassword"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="Old Password">
        <a-input v-model:value="user.old_password" placeholder="Please enter original password." type="password"/>
      </a-form-item>
      <a-form-item label="New Password">
        <a-input v-model:value="user.password" placeholder="Please enter new password." type="password"/>
      </a-form-item>
      <a-form-item label="Password Again">
        <a-input v-model:value="user.new_password" placeholder="Please enter new password again." type="password"/>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
      title="Reset Email"
      v-model:visible="resetEmailModalVisible"
      :confirm-loading="resetEmailModalLoading"
      @ok="handleResetEmail"
  >
    <a-form :model="user" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="New Email">
        <a-input v-model:value="user.new_email" placeholder="Please enter new email."/>
      </a-form-item>
    </a-form>
  </a-modal>

<!--  reset password email verification-->
  <a-modal v-model:visible="emailCodeModalVisible" :title="null" :footer="null" :closable="false"
           style="top: 50px; width: 400px">
    <p style="text-align: center; font-weight: bold; font-size: 18px">
      Please verify your email
    </p>
    <div style="display: flex; justify-content: space-between;">
      <a-input v-model:value="emailCode" placeholder="Email Verification" :maxlength="10" style="width: 80%;" />
      <a-button type="primary" block :disabled="isButtonDisabled" @click="sendEmailVerificationCode()" style="margin-left: 10px; width: 18%;">
        {{ isButtonDisabled ? countdownSeconds + 's' : 'Send' }}
      </a-button>
    </div>
    <a-button type="primary" danger block @click="resetPasswordAction" style="margin-top: 10px">Reset Password</a-button>
  </a-modal>

<!--  reset email first confirm-->
  <a-modal v-model:visible="resetEmailFirstConfirm" :title="null" :footer="null" :closable="false"
           style="top: 50px; width: 400px">
    <p style="text-align: center; font-weight: bold; font-size: 18px">
      Please verify your current email first
    </p>
    <div style="display: flex; justify-content: space-between;">
      <a-input v-model:value="emailCodeOld" placeholder="Old Email Verification" :maxlength="10" style="width: 80%;" />
      <a-button type="primary" block :disabled="isFirstButtonDisabled" @click="sendOldEmailVerificationCode()" style="margin-left: 10px; width: 18%;">
        {{ isFirstButtonDisabled ? countdownFirstSeconds + 's' : 'Send' }}
      </a-button>
    </div>
    <a-button type="primary" danger block @click="confirmEmailFirst" style="margin-top: 10px">Confirm</a-button>
  </a-modal>

<!--  reset email second confirm-->
  <a-modal v-model:visible="resetEmailSecondConfirm" :title="null" :footer="null" :closable="false"
           style="top: 50px; width: 400px">
    <p style="text-align: center; font-weight: bold; font-size: 18px">
      Please verify your new email
    </p>
    <div style="display: flex; justify-content: space-between;">
      <a-input v-model:value="emailCodeNew" placeholder="New Email Verification" :maxlength="10" style="width: 80%;" />
      <a-button type="primary" block :disabled="isSecondButtonDisabled" @click="sendNewEmailVerificationCode()" style="margin-left: 10px; width: 18%;">
        {{ isSecondButtonDisabled ? countdownSecondSeconds + 's' : 'Send' }}
      </a-button>
    </div>
    <a-button type="primary" danger block @click="confirmNewEmail" style="margin-top: 10px">Reset Email</a-button>
  </a-modal>

<!--  close account verification email-->
  <a-modal v-model:visible="closeEmailCodeVisible" :title="null" :footer="null" :closable="false"
           style="top: 50px; width: 400px">
    <p style="text-align: center; font-weight: bold; font-size: 18px">
      Please verify your email before closing this account
    </p>
    <div style="display: flex; justify-content: space-between;">
      <a-input v-model:value="emailCodeClose" placeholder="Email Verification" :maxlength="10" style="width: 80%;" />
      <a-button type="primary" block :disabled="isButtonCloseDisabled" @click="sendEmailCloseVerificationCode()" style="margin-left: 10px; width: 18%;">
        {{ isButtonCloseDisabled ? countdownCloseSeconds + 's' : 'Send' }}
      </a-button>
    </div>
    <a-button type="primary" danger block @click="closeAccountAction" style="margin-top: 10px">Reset Password</a-button>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import axios from "axios";
import {message} from "ant-design-vue";
import router from "@/router";
import {Tool} from "@/util/tool";

declare let hexMd5: any;
declare let KEY: any;

export default defineComponent({
  name: 'CustomerUser',

  setup() {
    const columns = [
      {
        title: 'Username',
        dataIndex: 'loginName'
      },
      {
        title: 'Nickname',
        dataIndex: 'name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Password',
        dataIndex: 'password'
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ];

    const users = ref();

    const handleSearch = () => {
      axios.get('/user/searchById/' +  store.state.user.id, {
        headers: {
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        users.value = []
        if (data.success) {
          users.value = [data.content];
        } else {
          message.error(data.message);
        }
      })
    }

    const user = ref();
    const resetNameModalVisible = ref(false);
    const resetNameModalLoading = ref(false);

    const handleResetNickname = () => {
      resetNameModalLoading.value = true;

      axios.post("/user/reset-name", user.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        resetNameModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          resetNameModalVisible.value = false;
          handleSearch();
        } else {
          message.error(data.message);
        }
      });
    };

    const resetNickname = (record: any) => {
      resetNameModalVisible.value = true;
      user.value = Tool.copy(record);
    };

    const resetPasswordModalVisible = ref(false);
    const resetPasswordModalLoading = ref(false);

    const emailCodeModalVisible = ref();
    const emailCode = ref();
    const isButtonDisabled = ref(false);
    const countdownSeconds = ref(0);
    let countdownInterval: number | null = null;
    const resetPasswordReq = ref({
      id: "",
      password: "",
      old_password: "",
      email_code: "",
      email: ""
    })

    const sendEmailVerificationCode = () => {
      if (isButtonDisabled.value) {
        return;
      }

      axios.get('/send-msg/' + users.value[0].email).then((response) => {
        resetPasswordModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isButtonDisabled.value = true;
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }
      countdownSeconds.value = 120;
      countdownInterval = setInterval(() => {
        countdownSeconds.value--;
        if (countdownSeconds.value <= 0) {
          clearInterval(countdownInterval as number);
          isButtonDisabled.value = false;
        }
      }, 1000);
    }

    const checkPassword = (password: string) => {
      const hasNumber = /\d/.test(password);
      const hasLetter = /[a-zA-Z]/.test(password);
      if (!hasNumber || !hasLetter) {
        return false;
      }
      return true;
    }

    const handleResetPassword = () => {
      if (!checkPassword(user.value.password) || !checkPassword(user.value.old_password)) {
        message.error("Password must contain both numbers and letters.");
        resetPasswordModalLoading.value = false;
        return false;
      }

      if (user.value.password !== user.value.new_password) {
        message.error("Password doesn't match.");
        resetPasswordModalLoading.value = false;
        return false;
      }

      resetPasswordModalLoading.value = true;
      emailCodeModalVisible.value = true;
      emailCode.value = "";
    };

    const resetPasswordAction = () => {
      resetPasswordReq.value.id = user.value.id;
      resetPasswordReq.value.old_password = hexMd5(user.value.old_password + KEY);
      resetPasswordReq.value.password = hexMd5(user.value.password + KEY);
      resetPasswordReq.value.email_code = emailCode.value;
      resetPasswordReq.value.email = user.value.email;

      axios.post("/user/reset-pswd", resetPasswordReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          resetPasswordModalVisible.value = false;
          emailCodeModalVisible.value = false;
          resetPasswordModalLoading.value = false;
          handleSearch();
        } else {
          message.error(data.message);
          resetPasswordModalLoading.value = false;
        }
      });

      resetPasswordModalLoading.value = false;
    }

    const resetPassword = (record: any) => {
      resetPasswordModalVisible.value = true;
      user.value = Tool.copy(record);
      user.value.password = null;
    };

    const resetEmailFirstConfirm = ref(false);
    const resetEmailModalVisible = ref(false);
    const resetEmailModalLoading = ref(false);
    const resetEmailSecondConfirm = ref(false);

    const isFirstButtonDisabled = ref(false);
    const countdownFirstSeconds = ref(0);
    const emailCodeOld = ref();
    let countdownFirstInterval: number | null = null;

    const resetEmail = (record: any) => {
      resetEmailFirstConfirm.value = true;
      isFirstButtonDisabled.value = false;
      user.value = Tool.copy(record);
    };

    const checkEmailReq = ref({
      old_email: "",
      email_code: ""
    })

    const sendOldEmailVerificationCode = () => {
      if (isFirstButtonDisabled.value) {
        return;
      }

      axios.get('/send-msg/' + user.value.email).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isFirstButtonDisabled.value = true;
      if (countdownFirstInterval) {
        clearInterval(countdownFirstInterval);
      }
      countdownFirstSeconds.value = 120;
      countdownFirstInterval = setInterval(() => {
        countdownFirstSeconds.value--;
        if (countdownFirstSeconds.value <= 0) {
          clearInterval(countdownFirstInterval as number);
          isFirstButtonDisabled.value = false;
        }
      }, 1000);
    }

    const confirmEmailFirst = () => {
      if (countdownFirstInterval) {
        clearInterval(countdownFirstInterval);
      }

      checkEmailReq.value.old_email = user.value.email;
      checkEmailReq.value.email_code = emailCodeOld.value;
      axios.post("/user/check-email", checkEmailReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          resetEmailModalVisible.value = true;
          resetEmailModalLoading.value = false;
          emailCodeOld.value = "";
        } else {
          message.error(data.message);
          emailCodeOld.value = "";
          resetEmailModalLoading.value = false;
        }
      });

      emailCodeOld.value = "";
      resetEmailFirstConfirm.value = false;
      resetEmailModalVisible.value = false;
    }

    const isSecondButtonDisabled = ref(false);
    const countdownSecondSeconds = ref(0);
    const emailCodeNew = ref();
    let countdownSecondInterval: number | null = null;

    const handleResetEmail = () => {
      if(!Tool.checkEmail(user.value.new_email)){
        message.error("Please enter valid email.");
        resetEmailModalLoading.value = false;
        return false;
      }

      resetEmailSecondConfirm.value = true;
      countdownSecondSeconds.value = 120;
      if (countdownSecondInterval) {
        clearInterval(countdownSecondInterval);
      }
    }

    const sendNewEmailVerificationCode = () => {
      if (isSecondButtonDisabled.value) {
        return;
      }

      axios.get('/send-msg/' + user.value.new_email).then((response) => {
        resetEmailModalLoading.value = false;
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      resetEmailModalLoading.value = false;
      isSecondButtonDisabled.value = true;
      if (countdownSecondInterval) {
        clearInterval(countdownSecondInterval);
      }
      countdownSecondSeconds.value = 120;
      countdownSecondInterval = setInterval(() => {
        countdownSecondSeconds.value--;
        if (countdownSecondSeconds.value <= 0) {
          clearInterval(countdownSecondInterval as number);
          isSecondButtonDisabled.value = false;
        }
      }, 1000);
    }

    const resetEmailReq = ref({
      id: store.state.user.id,
      email: "",
      email_code: ""
    })

    const confirmNewEmail = () => {
      resetEmailModalLoading.value = true;
      resetEmailReq.value.email = user.value.new_email;
      resetEmailReq.value.email_code = emailCodeNew.value;

      axios.post("/user/reset-email", resetEmailReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          resetEmailModalVisible.value = false;
          resetEmailModalLoading.value = false;
          resetEmailSecondConfirm.value = false;
          emailCodeNew.value = "";
          handleSearch();
        } else {
          message.error(data.message);
          emailCodeNew.value = "";
          resetEmailModalLoading.value = false;
        }
      });
    }

    const closeEmailCodeVisible = ref(false);
    const emailCodeClose = ref();
    const isButtonCloseDisabled = ref(false);
    const countdownCloseSeconds = ref(0);
    let countdownCloseInterval: number | null = null;

    const sendEmailCloseVerificationCode = () => {
      if (isButtonCloseDisabled.value) {
        return;
      }

      axios.get('/send-msg/' + users.value[0].email).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Email verification code sent!");
        } else {
          message.error(data.message);
        }
      })

      isButtonCloseDisabled.value = true;
      if (countdownCloseInterval) {
        clearInterval(countdownCloseInterval);
      }
      countdownCloseSeconds.value = 120;
      countdownCloseInterval = setInterval(() => {
        countdownCloseSeconds.value--;
        if (countdownCloseSeconds.value <= 0) {
          clearInterval(countdownCloseInterval as number);
          isButtonCloseDisabled.value = false;
        }
      }, 1000);
    }

    const closeAccount = (record: any) => {
      closeEmailCodeVisible.value = true;
    }

    const closeAccountReq = ref({
      id: "",
      email_code: ""
    })

    const closeAccountAction = () => {
      closeAccountReq.value.id = users.value[0].id;
      closeAccountReq.value.email_code = emailCodeClose.value;
      axios.post("/user/close-account", closeAccountReq.value, {
        headers : {
          'token': store.state.user.token,
          'id': store.state.user.id
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          closeEmailCodeVisible.value = false;
          emailCodeClose.value = "";
          message.success({
            content: 'Destined,we are to meet again.',
            duration: 5,
            onClose: () => {
              store.commit("setUser", {});
              router.push('/');
            }
          });
          setTimeout(() => {
            router.push('/');
          }, 5000);
        } else {
          message.error(data.message);
          emailCodeClose.value = "";
        }
      });
    }

    onMounted(() => {
      handleSearch();
    });

    return {
      columns,
      users,

      user,
      resetNameModalVisible,
      resetNameModalLoading,
      resetNickname,
      handleResetNickname,

      resetPassword,
      resetPasswordModalVisible,
      resetPasswordModalLoading,
      handleResetPassword,
      emailCodeModalVisible,
      emailCode,
      isButtonDisabled,
      countdownSeconds,
      sendEmailVerificationCode,
      resetPasswordAction,

      resetEmail,
      resetEmailFirstConfirm,
      confirmEmailFirst,
      resetEmailModalVisible,
      resetEmailModalLoading,
      handleResetEmail,
      resetEmailSecondConfirm,
      confirmNewEmail,
      sendNewEmailVerificationCode,
      isSecondButtonDisabled,
      countdownSecondSeconds,
      isFirstButtonDisabled,
      countdownFirstSeconds,
      emailCodeOld,
      emailCodeNew,
      sendOldEmailVerificationCode,

      closeAccount,
      closeEmailCodeVisible,
      emailCodeClose,
      isButtonCloseDisabled,
      countdownCloseSeconds,
      sendEmailCloseVerificationCode,
      closeAccountAction
    }
  }
})
</script>