<template>
  <h3 >Used For Axios Tests</h3>
  <a-button @click="onClick">Test</a-button>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'TheTest',

  setup() {
    const onClick = () => {
      console.log("clicked")
    }

    return {
      onClick,
    }
  }
})
</script>