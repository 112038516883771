import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';

import axios from 'axios';

import 'ant-design-vue/dist/antd.css';
import * as Icons from '@ant-design/icons-vue';
import {Tool} from "@/util/tool";
import { message } from 'ant-design-vue';

axios.defaults.baseURL = process.env.VUE_APP_SERVER;

/**
 * axios interceptors
 */
axios.interceptors.request.use(function (config) {
    // console.log('Params Request', config);
    const token = store.state.user.token;
    if (Tool.isNotEmpty(token)) {
        config.headers.token = token;
        // console.log("Add token to header:", token);
    }
    return config;
}, error => {
    const response = error.response;
    const status = response.status;
    if (status === 401) {
        // console.log("Please Login First. Redirect to Homepage");
        store.commit("setUser", {});
        message.error("Not Login");
        router.push('/').catch(err => {
            console.error(err);
        });
    }
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    // console.log('Return Content', response);
    return response;
}, error => {
    return Promise.reject(error);
});

const app = createApp(App);
app.use(store).use(router).use(Antd).mount('#app');

const icons: any = Icons;
for (const i in icons) {
    app.component(i, icons[i]);
}

// console.log('Environment:', process.env.NODE_ENV);
// console.log('Server:', process.env.VUE_APP_SERVER);