import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"flex":"1"} }
const _hoisted_2 = { style: {"color":"#504f4f","margin":"2px 0 1px"} }
const _hoisted_3 = { style: {"font-size":"16px","font-weight":"500","color":"red"} }
const _hoisted_4 = { style: {"margin-left":"40px"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_list, {
      class: "form-list",
      "item-layout": "horizontal",
      dataSource: _ctx.listData,
      pagination: { ..._ctx.paginationProps, current: _ctx.currentPage }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
          return (_openBlock(), _createBlock(_component_a_list_item, {
            key: item.id,
            style: _normalizeStyle({ display: 'flex', alignItems: 'center', userSelect: 'none', backgroundColor: item.read ? '#F5F5F5' : '#EAEAEA' })
          }, {
            actions: _withCtx(() => [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.handleDetailClick(item))
              }, "detail", 8, _hoisted_5),
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.handleReadClick(item))
              }, _toDisplayString(item.read ? 'read' : 'unread'), 9, _hoisted_6),
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.handleDeleteClick(item))
              }, "delete", 8, _hoisted_7)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_avatar, {
                size: "large",
                shape: "circle",
                style: _normalizeStyle({ backgroundColor: _ctx.getRandomColor(item.fromName), marginLeft: '10px', marginRight: '16px' })
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.fromName.charAt(0).toUpperCase()), 1)
                ]),
                _: 2
              }, 1032, ["style"]),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, [
                  _createTextVNode(_toDisplayString(item.fromName) + " reported " + _toDisplayString(item.name == "issue" ? "an" : "a") + " ", 1),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(item.name == "other"? "message" : item.name), 1)
                ]),
                _createElementVNode("h4", null, _toDisplayString(_ctx.truncateString(item.content, 50)), 1)
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.formatDate(item.date)), 1)
            ]),
            _: 2
          }, 1032, ["style"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["dataSource", "pagination"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.showDetailModal,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDetailModal) = $event)),
      title: "Form Request Detail",
      onCancel: _ctx.closeDetailModal,
      onOk: _ctx.closeDetailModal
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          onClick: _ctx.closeDetailModal
        }, {
          default: _withCtx(() => [
            _createTextVNode("OK")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.detailImage?.length)
          ? (_openBlock(), _createBlock(_component_a_image, {
              key: 0,
              width: 100,
              src: _ctx.detailImage,
              alt: "Item Image"
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        _createElementVNode("h3", null, "Title: " + _toDisplayString(_ctx.detailTitle), 1),
        _createElementVNode("p", null, "Content: " + _toDisplayString(_ctx.detailContent), 1),
        _createElementVNode("span", null, "Contact info: " + _toDisplayString(_ctx.detailEmail), 1)
      ]),
      _: 1
    }, 8, ["visible", "onCancel", "onOk"])
  ], 64))
}