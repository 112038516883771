<template>
  <template v-if="isMobile">
    <a-layout-footer style="text-align: center">
      <p v-show="user.id">Welcome {{user.name}}</p>
      Copyright © 2023 lulei 版權所有 | 赣ICP备2023002840号-1
    </a-layout-footer>
  </template>

  <template v-if="!isMobile">
    <portal to="body">
      <the-calendar v-if="showPopup" class="the-calendar" />
      <the-chat v-if="user.id && showChat" class="the-chat" />
    </portal>

    <a-row>
      <a-col :span="6">
        <div class="left-panel" @click="togglePopup">
          <template v-if="user.id">
            <div>
              <p class="vertical-p">{{ currentDate }}</p>
              <p class="vertical-p">{{ currentTime }}</p>
              <p class="vertical-p">{{ lunarDate }}</p>
            </div>
          </template>
          <template v-else>
            <p class="left-p">{{ currentDate }}</p>
            <p class="right-p">{{ currentTime }}</p>
            <p class="right-p">{{ lunarDate }}</p>
          </template>
        </div>
      </a-col>
      <a-col :span="13">
        <a-layout-footer class="center-footer">
          <p v-show="user.id">Welcome, {{ user.name }}</p>
          Copyright © 2023 lulei 版權所有 | 赣ICP备2023002840号-1
        </a-layout-footer>
      </a-col>

  <!--    chat box-->
      <a-col :span="3" style="display: flex; align-items: center; margin-left: auto;">
        <div v-show="user.id">
          <div :style="{fontSize: '30px', color: '#08c', cursor: 'pointer'}" title="Click to open chat box" @click="togglePopupChat" >💬</div>
        </div>
      </a-col>
      <a-col :span="2" style="display: flex; align-items: center; margin-left: auto;">
        <div v-show="user.id">
          <div :style="{fontSize: '30px', color: '#08c', cursor: 'pointer'}" title="Click to open chat box" @click="togglePopupChat" ></div>
        </div>
      </a-col>
    </a-row>
  </template>
</template>

<script lang="ts">
import {defineComponent, onMounted, computed, ref} from 'vue';
import store from "@/store";
import {Tool} from "@/util/tool";
import {message, notification} from 'ant-design-vue';
import axios from "axios";
import moment from 'moment';
import TheCalendar from "@/components/the-calendar.vue";
import lunisolar from 'lunisolar'
import TheChat from "@/components/the-friend.vue";

export default defineComponent({
  name: 'the-footer',
  components: {TheChat, TheCalendar},
  setup() {
    const user = computed(() => store.state.user);
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    const currentDate = ref('');
    const currentTime = ref('');
    const lunarDate = ref('');

    const updateDateTime = () => {
      currentDate.value = moment().format('YYYY-MM-DD');
      currentTime.value = moment().format('HH:mm:ss');
      lunarDate.value = lunisolar(new Date()).format('lY年 lMlD lH時');
    };

    const showPopup = ref(false);
    const showChat = ref(false);

    let websocket: any;
    let token: any;
    const onOpen = () => {
      // console.log('WebSocket connect successfully, state code: ', websocket.readyState)
    };
    const onMessage = (event: any) => {
      // console.log('WebSocket received msg:', event.data);
      notification['info']({
        message: 'Received message',
        description: event.data,
      });
    };
    const onError = () => {
      // console.log('WebSocket connect failed, state code:', websocket.readyState)
    };
    const onClose = () => {
      // console.log('WebSocket connection closed, state code: ', websocket.readyState)
    };
    const initWebSocket = () => {
      // connect successfully
      websocket.onopen = onOpen;
      // received msg
      websocket.onmessage = onMessage;
      // connect failed
      websocket.onerror = onError;
      // close connection
      websocket.onclose = onClose;
    };

    const handleSearch = () => {
      if (store.state.user.token) {
        axios.get('/user/searchById/' +  store.state.user.id, {
          headers: {
            'token': store.state.user.token
          }
        }).then((response) => {
          const data = response.data;
          if (data.success) {
            user.value.name = data.content.name;
          } else {
            message.error(data.message);
          }
        })
      }
    }

    const togglePopup = () => {
      showPopup.value = !showPopup.value;
    };

    const togglePopupChat = () => {
      showChat.value = !showChat.value;
    }

    const handleClickOutside = (event: MouseEvent) => {
      const calendarElement = document.querySelector('.the-calendar');
      const clickedElement = event.target as HTMLElement;

      const isClickedInsideCalendar = calendarElement && calendarElement.contains(clickedElement);
      const isClickedInsideLeftPanel = (clickedElement as HTMLElement).closest('.left-panel') !== null;

      if (showPopup.value && !isClickedInsideCalendar && !isClickedInsideLeftPanel) {
        showPopup.value = false;
      }
    };

    onMounted(() => {
      // WebSocket
      if ('WebSocket' in window) {
        token = Tool.uuid(10);
        // ws://127.0.0.1:8081/ws/xxx
        websocket = new WebSocket(process.env.VUE_APP_WS_SERVER + '/ws/' + token);
        initWebSocket()

        // websocket.close();
      } else {
        alert('Your browser does not support this application, please change a browser.')
      }

      // refresh user information
      handleSearch();

      updateDateTime();
      setInterval(updateDateTime, 1000);
      document.addEventListener('click', handleClickOutside);
    });

    return {
      user,

      currentDate: computed(() => currentDate.value),
      currentTime: computed(() => currentTime.value),
      lunarDate: computed(() => lunarDate.value),

      togglePopup,
      showPopup,
      showChat,

      togglePopupChat,

      isMobile
    }
  }
});
</script>

<style>
.center-footer {
  flex: 1;
  text-align: center;
  user-select: none;
}

.left-panel {
  display: flex;
  padding-left: 30px;
  padding-top: 5%;
  color: #007bff;
  user-select: none;
  cursor: pointer;
  width: 100%;
}

.left-p {
  margin-right: 5px;
}

.right-p {
  margin-left: 10px;
}

.vertical-p {
  margin-bottom: 5px;
}


.the-calendar {
  position: absolute;
  top: 30%;
  left: 30%;
  z-index: 999;
}

.the-chat {
  position: absolute;
  top: 20%;
  left: 20%;
  z-index: 999;
}
</style>