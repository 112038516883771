<template>
  <a-layout>
    <div class="notice-board">
      <div class="container">
        <h1 class="title">Notice Board</h1>
        <the-form></the-form>
      </div>
      <a-divider></a-divider>
      <a-tabs v-model:activeKey="activeKey" size="large">
        <a-tab-pane key="1" tab="Comments">
          <a-list
              class="form-list"
              item-layout="horizontal"
              :dataSource="listData"
              :pagination="{ ...paginationProps, current: currentPage }"
          >
            <a-list-item v-for="item in listData" :key="item.id" :style="{ display: 'flex', alignItems: 'center', userSelect: 'none', backgroundColor: item.read ? '#F5F5F5' : '#EAEAEA' }">
              <a-avatar
                  size="large"
                  shape="circle"
                  :style="{ backgroundColor: getRandomColor(item.fromName), marginLeft: '10px', marginRight: '16px' }"
              >
                {{ item.fromName.charAt(0).toUpperCase() }}
              </a-avatar>
              <div style="flex: 1;">
                <h3 style="color: #504f4f; margin: 2px 0 1px;">
                  {{ item.fromName === 'luleilulei' ? 'Lei Lu' : item.fromName }}
                  <span style="font-size: 12px; font-weight: 300; color: red">{{ item.name }}</span>
                  your message:
                </h3>
                <h4>{{ item.content }}</h4>
              </div>
              <template v-if="item.categoryId1 && item.categoryId2">
                <span v-html="formatLink(decodeLink(item.link))"></span>
              </template>
              <span style="margin-left: 40px">{{formatDate(item.date)}}</span>
              <template #actions>
                <a @click="handleRedirectClick(item)" >more</a>
                <a @click="handleReadClick(item)" >{{ item.read ? 'read' : 'unread' }}</a>
                <a @click="handleDeleteClick(item)" >delete</a>
              </template>
            </a-list-item>
          </a-list>
        </a-tab-pane>
        <a-tab-pane key="2" v-if="userAuth" tab="Requests">
          <admin-form />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Friends">
          <customer-friend />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";
import axios from "axios";
import store from "@/store";
import {message, Modal} from "ant-design-vue";
import TheForm from "@/components/the-form.vue";
import AdminForm from "@/views/admin/admin-form.vue";
import CustomerFriend from "@/views/customer/customer-friend.vue";

export default defineComponent({
  name: 'customer-notice',
  components: {CustomerFriend, AdminForm, TheForm},
  setup() {
    const listData = ref();
    const currentPage = ref(1);
    let userAuth;
    userAuth = store.state.user.loginName === "luleilulei";

    const formQueryReq = ref({
      page: 1,
      size: 50,
      toId: store.state.user.id,
      toName: store.state.user.name
    })

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const now = new Date();
      const diff = Math.abs(now.getTime() - date.getTime());
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (diff < 1000 * 60) {
        return 'Just now';
      } else if (diff < 1000 * 60 * 60) {
        return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      } else if (diff < 1000 * 60 * 60 * 24 * 30) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
      } else {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    }

    const getRandomColor = (id: any) => {
      const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff0000', '#0000ff'];

      const storedColor = localStorage.getItem(id);
      if (storedColor) {
        return storedColor;
      } else {
        const randomIndex = Math.floor(Math.random() * colorList.length);
        const randomColor = colorList[randomIndex];
        localStorage.setItem(id, randomColor);
        return randomColor;
      }
    }

    const handleFormQuery = (params: any) => {
      listData.value = [];
      formQueryReq.value.page = params.page;
      formQueryReq.value.size = params.size;
      axios.post("/form/list", formQueryReq.value, {
        headers: {
          'token': store.state.user.token,
          'id': store.state.user.id,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        const data = response.data;

        if (data.success) {
          listData.value = data.content.list;
          paginationProps.value.total = data.content.total;
        }
      });
    }

    const paginationProps = ref({
      showQuickJumper: true,
      pageSize: 6,
      total: 0,

      onChange: (page: number) => {
        currentPage.value = page;
        handleFormQuery({
          page: currentPage.value,
          size: 6
        });
      }
    })

    const handleReadClick = (item: any) => {
      if (!item.read) {
        axios.get("/form/read/" + item.id, {
          headers: {
            'token': store.state.user.token,
            'id': store.state.user.id
          }
        }).then((response) => {
          const data = response.data;
          if (data.success) {
            message.success('Marked as read');
            handleFormQuery({
              page: currentPage.value,
              size: 6
            });
          } else {
            message.error(data.message);
          }
        });
      }
    }

    const handleRedirectClick = (item: any) => {
      if (item.categoryId1) {
        handleReadClick(item);
        window.location.href = `/doc?ebookId=${item.categoryId1}`;
      }
    }

    const decodeLink = (link: string) => {
      let str = "";
      const index = link.lastIndexOf("/");
      if (index !== -1) {
        const ebookName = link.substring(0, index);
        const docName = link.substring(index + 1);
        str = `Ebook Name: ${ebookName}, Doc Name: ${docName}`;
      }
      return str;
    };

    const formatLink = (link: string) => {
      const startIndex = link.indexOf(':') + 1;
      const endIndex = link.indexOf(',');
      const ebookName = link.substring(startIndex, endIndex).trim();
      const docName = link.substring(endIndex + 1).trim();

      const formattedLink = `Ebook Name: <b style="color: blue">${ebookName}</b>, Doc Name: <b style="color: blue">${docName}</b>`;
      return formattedLink;
    }

    const handleDeleteClick = (item: any) => {
      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to delete this message? This action is irreversible!',
        onOk() {
          axios.get("/form/delete-form/" + item.id, {
            headers: {
              'token': store.state.user.token,
              'id': store.state.user.id
            }
          }).then((response) => {
            const data = response.data;
            if (data.success) {
              message.success('Deleted!');
              let pageNum = currentPage.value;
              if ((paginationProps.value.total - 1) % paginationProps.value.pageSize == 0) {
                pageNum = Math.max(1, pageNum-1);
              }
              handleFormQuery({
                page: pageNum,
                size: paginationProps.value.pageSize
              });
            } else {
              message.error(data.message);
            }
          });
        }
      });
    }

    onMounted(() => {
      userAuth = store.state.user.loginName === "luleilulei";
      handleFormQuery({
        page: 1,
        size: 6
      });
    });

    return {
      listData,
      currentPage,
      paginationProps,
      formatDate,
      getRandomColor,

      handleReadClick,
      handleRedirectClick,
      formatLink,
      decodeLink,
      handleDeleteClick,

      activeKey: ref('1'),
      userAuth,
    };
  },
});
</script>

<style>
.notice-board {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

.form-list {
  min-height: 480px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>