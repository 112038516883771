<template>
  <div
      class="chat-container"
      ref="chatContainer"
      :style="{
      transform: `translate(${translateX}px, ${translateY}px)`,
      width: `${width}px`,
      height: `${height}px`
    }"
  >
    <div class="chat-box">
      <div class="title-bar" @mousedown="startDrag">
        <span class="chat-title">✉️ Chat Box</span>
        <div class="buttons">
          <span class="maximize-button" title="Maximize the window size" @click="maximizeChatBox"><ArrowsAltOutlined /></span>
          <span class="minimize-button" title="Back to original size" @click="minimizeChatBox"><ShrinkOutlined /></span>
          <span class="close-button" title="Close chat box" @click="closeChatBox"><CloseOutlined /></span>
        </div>
      </div>
      <a-row class="content">
        <a-col class="search-bar" :span="9">
          <input type="text" style="width: 80%" v-model="searchQuery" placeholder="Search Friends" />
          <a-dropdown :trigger="['click']">
            <span class="search-icon">🔍</span>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0">
                  <div @click="handleSearchIDClick">Search By ID</div>
                </a-menu-item>
                <a-menu-item key="1">
                  <div @click="handleSearchUsernameClick">Search By Username</div>
                </a-menu-item>
                <a-menu-item key="3">
                  <div @click="handleSearchNicknameClick">Search By Nickname</div>
                </a-menu-item>
                <a-menu-item key="4">
                  <div @click="handleSearchInFollowing">Search In Following</div>
                </a-menu-item>
                <a-menu-item key="5">
                  <div @click="handleSearchInFollower">Search In Followers</div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <span class="search-icon" title="Refresh friend list" @click="handleRefreshFriendListClick">🔄</span>
          <a-tabs v-model:activeKey="activeKey" size="small">
            <a-tab-pane key="1" tab="Following">
              <div>
                <div v-if="followingList?.length === 0" style="font-weight: bold">List is Empty!</div>
                <div v-for="item in followingList" :key="item.id" class="custom-card">
                  <div class="custom-header">
                    <div class="header-column">
                      <div><span style="font-weight: bold; color: #888;">Username:</span> {{ item.loginName }}</div>
                      <div><span style="font-weight: bold; color: #888;">Nickname:</span> {{ item.name }}</div>
                    </div>
                    <div class="header-column">
                      <a-button type="primary" class="message-button" size="small" @click="handleMessageClick(item)">Message</a-button>
                      <a-popconfirm
                          title="Are you sure unfollow this user?"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="handleUnfollowClick(item)"
                      >
                        <a-button type="primary" danger class="unfollow-button" size="small">Unfollow</a-button>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Follower">
              <div>
                <div v-if="followerList?.length === 0" style="font-weight: bold">List is Empty!</div>
                <div v-for="item in followerList" :key="item.id" class="custom-card">
                  <div class="custom-header">
                    <div class="header-column">
                      <div><span style="font-weight: bold; color: #888;">Username:</span> {{ item.loginName }}</div>
                      <div><span style="font-weight: bold; color: #888;">Nickname:</span> {{ item.name }}</div>
                      <div><span style="font-weight: bold; color: #888;" :title="item.email">Email:</span> {{ truncateString(item.email, 15) }}</div>
                    </div>
                    <div class="header-column">
                      <a-button type="primary" class="message-button" size="small" @click="handleMessageClick(item)">Message</a-button>
                      <a-button type="primary" class="unfollow-button" size="small" @click="handleFollowClick(item)">Follow</a-button>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Groups">Content of Tab Pane 3</a-tab-pane>
          </a-tabs>
        </a-col>
        <a-col class="friend-list" :span="15">
          <a-row style="height: 100%;">
            <a-col :span="1">
              <a-divider type="vertical" style="height: 100%; background-color: #7cb305"></a-divider>
            </a-col>
            <a-col :span="23">
<!--              search modal-->
              <div v-show="modalSearchVisible" title="Users">
                <div class="card-grid">
                  <div v-for="item in searchList" :key="item.id" class="card">
                    <div class="card-header">
                      <span>Username: {{ item.loginName }}</span>
                      <a-button class="follow-button" @click="handleFollowClick(item)">Follow</a-button>
                    </div>
                    <div class="card-body">Nickname: {{ item.name }}</div>
                  </div>
                </div>
              </div>

<!--              chat modal-->
              <div v-show="modalChatVisible"  title="Chats">
                <the-chat :chatData="chatData" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <div class="resize-handle" @mousedown="startResize" title="Drag to resize"></div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import axios from "axios";

import {message} from "ant-design-vue";
import TheChat from "@/components/the-chat";

export default defineComponent({
  components: {
    TheChat
  },
  setup() {
    const searchQuery = ref("");
    const isDragging = ref(false);
    const isResizing = ref(false);
    const startX = ref(0);
    const startY = ref(0);
    const dragOffsetX = ref(0);
    const dragOffsetY = ref(0);
    const translateX = ref(0);
    const translateY = ref(0);
    const width = ref(800); // 初始宽度
    const height = ref(500); // 初始高度
    const searchList = ref();
    const activeKey = ref('1');
    const followingList = ref();
    const followerList = ref();
    const modalSearchVisible = ref(false);
    const modalChatVisible = ref(false);
    const lastRefreshTime = ref();

    const chatData = ref({
      fromId: "0",
      toId: "0",
      fromLoginName: "",
      toLoginName: "",
      fromName: "",
      toName: ""
    });

    const friendFollowReq = ref({
      userId: "",
      followerId: ""
    })

    const formSaveReq = ref({
      name: "",
      fromId: 0,
      fromName: "",
      fromEmail: "",
      toId: 0,
      toName: "",
      emergency: 0,
      content: "",
      contentId: 0
    });

    const clearChatData = () => {
      chatData.value.fromId = "";
      chatData.value.fromLoginName = "";
      chatData.value.fromName = "";
      chatData.value.toId = "";
      chatData.value.toLoginName = "";
      chatData.value.toName = "";
    }

    const clearFriendFollowReq = () => {
      friendFollowReq.value.userId = "";
      friendFollowReq.value.followerId = "";
    }

    const clearFormSaveReq = () => {
      formSaveReq.value.name = "";
      formSaveReq.value.fromId = 0;
      formSaveReq.value.fromName = "";
      formSaveReq.value.fromEmail = "";
      formSaveReq.value.toId = 0;
      formSaveReq.value.toName = "";
      formSaveReq.value.emergency = 0;
      formSaveReq.value.content = "";
      formSaveReq.value.contentId = 0;
    }

    const closeChatBox = () => {
      const chatContainer = document.querySelector('.chat-container');
      chatContainer.style.display = 'none';
    }

    const maximizeChatBox = () => {
      width.value = window.innerWidth; // 将宽度设置为窗口的宽度
      height.value = window.innerHeight; // 将高度设置为窗口的高度
      translateX.value = -300; // 将水平偏移量设置为 0
      translateY.value = -150; // 将垂直偏移量设置为 0
    }

    const minimizeChatBox = () => {
      width.value = 800; // 恢复初始宽度
      height.value = 500; // 恢复初始高度
      translateX.value = 0; // 将水平偏移量设置为 0
      translateY.value = 0; // 将垂直偏移量设置为 0
    };

    const startDrag = (event) => {
      if (!isDragging.value) {
        isDragging.value = true;
        startX.value = event.clientX;
        startY.value = event.clientY;
        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', stopDrag);
        document.body.style.userSelect = 'none'; // 防止鼠标选中文本
      }
    };

    const drag = (event) => {
      if (isDragging.value) {
        const deltaX = event.clientX - startX.value;
        const deltaY = event.clientY - startY.value;
        translateX.value += deltaX;
        translateY.value += deltaY;
        startX.value = event.clientX;
        startY.value = event.clientY;
      }
    };

    const stopDrag = () => {
      if (isDragging.value) {
        isDragging.value = false;
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', stopDrag);
        document.body.style.userSelect = 'auto'; // 恢复鼠标文本选中
      }
    };

    const startResize = (event) => {
      isResizing.value = true;
      startX.value = event.clientX;
      startY.value = event.clientY;
      document.addEventListener("mousemove", resize);
      document.addEventListener("mouseup", stopResize);
      document.body.style.userSelect = "none"; // 防止鼠标选中文本
    };

    const resize = (event) => {
      if (isResizing.value) {
        const deltaX = event.clientX - startX.value;
        const deltaY = event.clientY - startY.value;

        // 更新宽度和高度
        width.value += deltaX;
        height.value += deltaY;

        startX.value = event.clientX;
        startY.value = event.clientY;
      }
    };

    const stopResize = () => {
      isResizing.value = false;
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", stopResize);
      document.body.style.userSelect = "auto"; // 恢复鼠标文本选中
    };

    const handleSearchIDClick = () => {
      if (searchQuery.value == null || searchQuery.value.length === 0) {
        message.error("Invalid search info.");
        return ;
      }

      searchList.value = [];
      axios.get('/user/searchByUserId/' + parseInt(searchQuery.value), {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          if (data.content?.id) {
            searchList.value.push(data.content);
          } else {
            message.success("No such user!");
          }

          modalChatVisible.value = false;
          clearChatData();
          modalSearchVisible.value = true;
          searchQuery.value = "";
        } else {
          message.error(data.message);
        }
      })
    }

    const handleSearchUsernameClick = () => {
      if (searchQuery.value == null || searchQuery.value.length === 0) {
        message.error("Invalid search info.");
        return ;
      }

      searchList.value = [];
      axios.get('/user/searchByLoginName/' + searchQuery.value, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          searchList.value = data.content;
          if (searchList.value?.length === 0) {
            message.success("No such user!");
          }
          searchQuery.value = "";
          modalChatVisible.value = false;
          clearChatData();
          modalSearchVisible.value = true;
        } else {
          message.error(data.message);
        }
      })
    }

    const handleSearchNicknameClick = () => {
      if (searchQuery.value == null || searchQuery.value.length === 0) {
        message.error("Invalid search info.");
        return ;
      }

      searchList.value = [];
      axios.get('/user/searchByNickname/' + searchQuery.value, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          searchList.value = data.content;
          if (searchList.value?.length === 0) {
            message.success("No such user!");
          }
          searchQuery.value = "";
          modalChatVisible.value = false;
          clearChatData();
          modalSearchVisible.value = true;
        } else {
          message.error(data.message);
        }
      })
    }

    const searchFollowingList = () => {
      followingList.value = [];
      axios.get('/friend/following/' + store.state.user.id, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          followingList.value = data.content?.reverse();
        } else {
          message.error(data.message);
        }
      })
    }

    const searchFollowerList = () => {
      followerList.value = [];
      axios.get('/friend/follower/' + store.state.user.id, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          followerList.value = data.content;
        } else {
          message.error(data.message);
        }
      })
    }

    const handleRefreshFriendListClick = () => {
      const currentTime = new Date().getTime();
      const halfMinute = 20 * 1000; // 20秒，单位为毫秒

      if (currentTime - lastRefreshTime.value < halfMinute) {
        message.error("Do not refresh too frequently!")
        return; // 如果距离上次刷新时间不足20s，则直接返回
      }


      searchFollowingList();
      searchFollowerList();
      lastRefreshTime.value = currentTime;
      message.success("Refresh List Successfully!")
    }

    const handleFollowClick = (item) => {
      friendFollowReq.value.userId = store.state.user.id;
      friendFollowReq.value.followerId = item.id;

      axios.post('/friend/follow',  friendFollowReq.value, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          if (data.content) {
            message.success("Follow success!")
            searchFollowingList();

            // send form request
            formSaveReq.value.name = "followed";
            formSaveReq.value.emergency = 4;
            formSaveReq.value.toId = item.id;
            formSaveReq.value.toName = item.loginName;
            formSaveReq.value.fromId = store.state.user.id;
            formSaveReq.value.fromName = store.state.user.loginName;
            formSaveReq.value.fromEmail = store.state.user.email;
            formSaveReq.value.content = "followed";
            formSaveReq.value.contentId = 0;

            return axios.post("/form/send-form", formSaveReq.value, {
              headers: {
                'token': store.state.user.token,
                'id': store.state.user.id
              }
            });
          } else {
            message.error("You have already followed this user.")
          }

          clearFriendFollowReq();
        } else {
          message.error(data.message);
        }
      }).then(() => {
        clearFormSaveReq();
      });
    }

    const handleUnfollowClick = (item) => {
      friendFollowReq.value.userId = store.state.user.id;
      friendFollowReq.value.followerId = item.id;

      axios.post('/friend/unfollow',  friendFollowReq.value, {
        headers: {
          'id': store.state.user.id,
          'token': store.state.user.token
        }
      }).then((response) => {
        const data = response.data;
        if (data.success) {
          message.success("Unfollow success!")
          searchFollowingList();

          friendFollowReq.value.userId = "";
          friendFollowReq.value.followerId = "";
        } else {
          message.error(data.message);
        }
      })
    }

    const handleSearchInFollowing = () => {
      if (searchQuery.value == null || searchQuery.value.length === 0) {
        message.error("Invalid search info.");
        return ;
      }

      let temp = [];
      for (let item of followingList.value) {
        if (item.loginName.includes(searchQuery.value) || item.name.includes(searchQuery.value)) {
          temp.push(item);
        }
      }

      followingList.value = temp;
      searchQuery.value = "";
    }

    const handleSearchInFollower = () => {
      if (searchQuery.value == null || searchQuery.value.length === 0) {
        message.error("Invalid search info.");
        return ;
      }

      let temp = [];
      for (let item of followerList.value) {
        if (item.loginName.includes(searchQuery.value) || item.name.includes(searchQuery.value)) {
          temp.push(item);
        }
      }

      followerList.value = temp;
      searchQuery.value = "";
    }

    const truncateString = (str, length) => {
      if (str.length <= length) {
        return str;
      } else {
        return str.substring(0, length) + "...";
      }
    }

    const handleMessageClick = (item) => {
      chatData.value.fromId = store.state.user.id;
      chatData.value.fromLoginName = store.state.user.loginName;
      chatData.value.fromName = store.state.user.name;
      chatData.value.toId = item.id;
      chatData.value.toLoginName = item.loginName;
      chatData.value.toName = item.name;

      modalSearchVisible.value = false;
      clearFriendFollowReq();
      modalChatVisible.value = true;
    }

    onMounted(() => {
      searchFollowingList();
      searchFollowerList();
    });

    return {
      searchQuery,
      isDragging,
      isResizing,
      startX,
      startY,
      dragOffsetX,
      dragOffsetY,
      translateX,
      translateY,
      width,
      height,
      activeKey,
      followingList,
      followerList,
      modalSearchVisible,
      modalChatVisible,
      searchList,
      chatData,

      closeChatBox,
      maximizeChatBox,
      minimizeChatBox,
      startDrag,
      startResize,
      truncateString,

      handleSearchIDClick,
      handleSearchUsernameClick,
      handleSearchNicknameClick,
      handleSearchInFollowing,
      handleSearchInFollower,
      handleRefreshFriendListClick,
      handleFollowClick,
      handleUnfollowClick,
      handleMessageClick,
    }
  }
})
</script>

<style>
.chat-container {
  position: fixed;
  width: 800px;
  height: 500px;
  bottom: 20px;
  right: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: top 0.3s, left 0.3s;
  resize: both;
  overflow: auto;
  overflow-x: hidden;
  cursor: move;
}

.chat-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ddd;
  cursor: grab;
}

.chat-title {
  font-weight: bold;
}

.buttons {
  display: flex;
  margin-right: 10px;
}

.close-button,
.maximize-button,
.minimize-button {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.close-button {
  color: red;
}

.content {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar input {
  flex-grow: 1;
  padding: 6px;
  border: none;
  border-radius: 4px;
}

.search-icon {
  margin-left: 10px;
  cursor: pointer;
}

.friend-list {
  display: flex;
}

.resize-handle {
  position: relative;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #ddd;
  cursor: nwse-resize;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 8px;
}

.follow-button {
  margin-left: 16px;
}

.custom-card {
  margin-bottom: 10px;
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Custom header styles */
}

.header-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.message-button {
  margin-bottom: 8px;
}
</style>